import { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form, Accordion, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../Utils/API";
import InputGroup from 'react-bootstrap/InputGroup';
import './styles.scss';
import { GET_CLOUD_ACCOUNTS, GET_CLOUD_ACCOUNTS_DOWNLOAD_CSP_REPORT_CSV, GET_CLOUD_ACCOUNTS_DOWNLOAD_GWS_REPORT_CSV, GET_CUSTOMERS_LIST, RESELLER_API } from "../../../Utils/GlobalConstants";

// Images
import gcpProductImage from '../../../Assets/Images/Illustrations/gcp.svg';
import gwsProductImage from '../../../Assets/Images/Illustrations/googleWorkspace.svg';
import ibmProductImage from '../../../Assets/Images/Illustrations/ibmCloud.svg';
import avastProductImage from '../../../Assets/Images/Illustrations/avast.svg';
import azureProductImage from '../../../Assets/Images/Illustrations/azure.svg';
import cspProductImage from '../../../Assets/Images/Illustrations/csp.svg';
import acronisProductImage from '../../../Assets/Images/Illustrations/acronis.svg';
import ManagementConsoleFilterBox from "./LocalComponents/FilterBox";
import loadingGif from "../../../Assets/Images/Animations/loading-management-console.gif"
import { ChevronUp } from "react-bootstrap-icons";
import Toast from "../../../GlobalComponents/Toast";
import NoCustomer from '../../../Assets/Images/Illustrations/noCustomer.svg'
import serverErrorCustomer from '../../../Assets/Images/Illustrations/serverErrorCustomer.svg'
import AccessDenied from "../../../GlobalComponents/EmptyStates/AccessDenied";
import ManagementConsoleWidgets from "./LocalComponents/Widgets";
import ResellerNavdropdown from "../../../GlobalComponents/ResellerNavdropdown";

const ManagementConsole = (props) => {
    const navigate = useNavigate();                                                 // to use navigate function from react router dom
    const [loading, setLoading] = useState(false);                                  // for logging purpose
    const location = useLocation();                                                 // use to access data from other page data

    const date = new Date();                                                        // for js date time function
    const [showscrollToTop, setShowscrollToTop] = useState(false);                  // Scroll to Top Button
    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Manage") ? true : false);
    const [isDisabledCustomer, setIsDisabledCustomer] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Customers") ? true : false);

    const [searchIconFlag, setSearchIconFlag] = useState(location.state !== null ? true : false);                                     // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(location.state !== null ? location.state.searchData : null);             // storing searching value in the search input box
    const [status, setStatus] = useState();                                         // data in status dropdown
    const [serviceId, setServiceId] = useState();                                   // Service ID
    const [accountType, setAccountType] = useState();                               // Account Type
    const [sortCol, setSortCol] = useState("");                                     // Sorted Column Name. Default Empty
    const [sortDir, setSortDir] = useState("");                                     // Sorted ASC or DESC
    const [pageNo, setPageNo] = useState(1);                                        // Default Page Number
    const [pageSize, setPageSize] = useState(15);                                   // Records Loading Per Page
    const [filterFlag, setFilterFlag] = useState(false);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                  // empty search response check state in table during api call to load data
    const searchInputRef = useRef();                                                // Search Input Box

    const [accountDetails, setAccountDetails] = useState([]);                       // Get Account Details
    const [totalCustomerCount, setTotalCustomerCount] = useState(0);                // Total Customer Count Default '0'

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message usestate

    const [isLoading, setIsLoading] = useState(true);                               // Loading on Scroll
    const [isInitialLoading, setIsInitialLoading] = useState(false);                // Loading on initial load
    const [infinityScroll, setInfinityScroll] = useState(false);                    // Iitially and Once all records loaded It will be false.

    const [statusLastUsed, setStatusLastUsed] = useState(null);                     // data in status dropdown
    const [serviceIdLastUsed, setServiceIdLastUsed] = useState(null);               // Service ID
    const [accountTypeLastUsed, setAccountTypeLastUsed] = useState(null);           // Account Type

    const [emptyCustomerFlag, setEmptyCustomerFlag] = useState(false);              // empty custom condition check
    const [serverErrorCustomerFlag, setServerErrorCustomerFlag] = useState(false);  // empty custom condition check

    // reseller dropdown for  staff users
    const [resellerData, setResellerData] = useState([]);                            // data in customer dropdown
    const [resellerNameDefaultValue, setResellerNameDefaultValue] = useState((location.state?.resellerId !== null && location.state?.resellerId !== undefined && location.state?.resellerId !== "") ? location.state?.resellerId : "Resellers");
    const [resellerNameSearchValue, setResellerNameSearchValue] = useState();
    const [resellerName, setResellerName] = useState([]);
    const [resellerNameFiltered, setResellerNameFiltered] = useState(resellerName);
    const updatedResellerName = [];
    const [resellerId, setResellerId] = useState(location.state?.resellerId !== null ? location.state?.resellerId : "");
    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);
    // const [emptyReseller, setEmptyReseller] = useState(false);                  // empty table check state in table during api call to load data for reseller dropdown

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Reseller Section in Filter Box
    async function GetAllResellers() {
        await api.get(RESELLER_API, config)
            .then(resp => {
                setResellerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };
    
    // Function to call all dropdown values in Service Section 
    async function GetCloudAccounts(pageNo, pageSize, status, serviceId, accountType, search) {
        (status === undefined || status === null) ? status = "" : status = status;
        (search === undefined || search === null) ? search = "" : search = searchValueTable;
        (serviceId === undefined || serviceId === null) ? serviceId = "" : serviceId = serviceId;
        (accountType === undefined || accountType === null) ? accountType = "" : accountType = accountType;
        await api.get(`${GET_CLOUD_ACCOUNTS}?pageNo=${pageNo}&pageSize=${pageSize}&searchText=${search}&status=${status}&accountType=${accountType}&serviceId=${serviceId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {

                setServerErrorCustomerFlag(false);
                if (resp.status == 200) {
                    setEmptyCustomerFlag(false);
                    if (((searchValueTable && searchValueTable.length >= 0) || (serviceId && serviceId.length >= 0) || ((status && status.length >= 0)) || (accountType && accountType.length >= 0)) && pageNo === 1) {
                        setAccountDetails(resp.data.content);
                    }
                    else {
                        if(pageNo==1){
                            setAccountDetails(resp.data.content);  
                        }
                        else{
                        setAccountDetails(prevItems => [...prevItems, ...resp.data.content]);
                    }}
                    setTotalCustomerCount(resp.data.totalCount)
                    // console.log("accountDetails", accountDetails.length);
                    //  console.log("totalCustomerCount", totalCustomerCount);
                    // console.log("accountDetails.length", accountDetails.length)

                    if ((resp.data.content).length > 4 && ((accountDetails.length + pageSize) < (resp.data.totalCount))) {
                        setInfinityScroll(true);
                    } else {
                        setInfinityScroll(false);
                    }
                    setIsInitialLoading(false);
                }
                else {
                    if (pageNo === 1) {
                        setEmptyCustomerFlag(true);
                        setTotalCustomerCount(0);

                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorCustomerFlag(true);
                    setEmptyCustomerFlag(false);
                    setIsInitialLoading(false);
                }

            });
    };

    // useEffect to get all cloud accounts
    useEffect(() => {
        if (accountDetails.length === 0) {
            setIsInitialLoading(true);
        }
        GetCloudAccounts(pageNo, pageSize, status, serviceId, accountType, searchValueTable)
    }, [pageNo, pageSize]);

    // Loading Records on Scroll
    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            setPageNo(prev => prev + 1);
            setShowscrollToTop(true);
        }
    }

    // Scroll to Top with Button Click
    const scrollToTop = () => {
        window.scrollTo(0, 0);
        setShowscrollToTop(false);
    }

    // Scroll to Top without clicking Button
    const handleNavigation = (e) => {
        const window = e.currentTarget;

        if (window.scrollY == 0) {
            setShowscrollToTop(false)
        } else if (window.scrollY > 200) {
            // setShowscrollToTop(true)
        }

    };

    // useEffct to Initial Scroll
    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));
        { isCloudAdmin && GetAllResellers(); }
        // console.log("vidhiiii",resellerNameDefaultValue,resellerId);
    }, []);

     // useEffct to select reseller for staff user
     useEffect(() => {
        if(resellerId != null && resellerId != "")
        {setStatus(null);
        setServiceId(null);
        setAccountType(null);
        setSearchValueTable("");
        setFilterFlag(false);
        setPageNo(1);
        setAccountDetails([]);
        GetCloudAccounts(1, pageSize, null, null, null, null);
        }
    }, [resellerId]);

    // useEffct to Scroll Initial
    useEffect(() => {
        localStorage.getItem("navigation_link") ? localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link", "/management-console/");
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [])

   useEffect(() => {
        if (searchValueTable !== null) {
            const getData = setTimeout(() => {
                setPageNo(1);
                GetCloudAccounts(1, pageSize, status, serviceId, accountType, searchValueTable)
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])


    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const file_date_suffix = String(date.getDate()).padStart(2, '0') + String(date.getMonth() + 1).padStart(2, '0') + String(date.getFullYear()).padStart(2, '0') + String(date.getHours()).padStart(2, '0') + String(date.getMinutes()).padStart(2, '0');

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(url, name, fileType) {
        let configuration;
        configuration = config;

        await api.get(`${url}?requestedResellerCode=${resellerId}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    useEffect(() => {
        if (showFilterBox) {
            document.body.classList.add('overflow-hidden-sm');
        } else {
            document.body.classList.remove('overflow-hidden-sm');
        }

        return () => {
            document.body.classList.remove('overflow-hidden-sm');
        };
    }, [showFilterBox]);

    return (
        <>
            {showscrollToTop && <div className="scrollToTop" onClick={scrollToTop}>
                <ChevronUp color="#ffffff" size={24} />
            </div>}
            <div className="main-content">
                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
                {!isDisabled && <Container fluid>
                    <div className="service-management-azure-body user-management-console-main-div mb-5">
                        <div className="mgmt-console-table-row">
                            <div className="mb-3 d-flex">
                                <Col>
                                    <div className="my-0 table-heading row px-2">
                                        <div className="pl-1 col-md-3">
                                            <h1 className="mb-0 mobile-padding-search-open subscription-heading-table-header">Management Console</h1>
                                        </div>
                                    </div>
                                    <Row>
                                    {isCloudAdmin && <Col md={3}>
                                    <ResellerNavdropdown
                                    formGroupId="customerNameId"
                                    placeholder="Enter Reseller Code"
                                    selectDefaultValue={resellerNameDefaultValue}
                                    setSelectDefaultValue={setResellerNameDefaultValue}
                                    setOptionId={setResellerId}
                                    selectOptions={resellerData}
                                    selectOptionsFiltered={resellerNameFiltered}
                                    setSelectOptionsFiltered={setResellerNameFiltered}
                                    selectSearchValue={resellerNameSearchValue}
                                    setSelectSearchValue={setResellerNameSearchValue}
                                    updatedOptions={updatedResellerName}
                                />
                                    </Col>}
                                    </Row>
                                    <ManagementConsoleWidgets Logout={props.Logout} requestedResellerCode={resellerId}/>
                                    {emptyCustomerFlag &&
                                        <>
                                            <Row className="mt-2">
                                                <Col className="d-flex align-items-center" md={3}>
                                                    <p className="mb-0">Customers ({totalCustomerCount})</p>
                                                </Col>
                                                {/* Show only in desktop and Tablet */}
                                                <Col className="px-1 position-relative d-md-block d-none" md={9}>
                                                    <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                        <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative  search-filter-block">
                                                            {!loading && !searchIconFlag &&
                                                                <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                            }
                                                            {!loading && searchIconFlag &&

                                                                <InputGroup className="search-input-box">
                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                        <span className="searchgrey cpointer"
                                                                            onClick={() => {
                                                                                setPageNo(1);
                                                                                // GetCloudAccounts(1, pageSize, status, serviceId, accountType, searchValueTable)
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </InputGroup.Text>
                                                                    <Form.Control
                                                                        placeholder="Search for Company / Customer / Service"
                                                                        aria-label="Search for Company / Customer / Service"
                                                                        aria-describedby="basic-addon2"
                                                                        className="search-input-box-input"
                                                                        value={searchValueTable}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length === 0) {
                                                                                setPageNo(1);
                                                                                setSearchValueTable(null);
                                                                                // GetCloudAccounts(1, pageSize, status, serviceId, accountType, null)
                                                                            }
                                                                            else {
                                                                                setSearchValueTable(e.target.value)
                                                                            }
                                                                        }}
                                                                        ref={searchInputRef}
                                                                    />
                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                        onClick={() => {
                                                                            setPageNo(1);
                                                                            setSearchValueTable("");
                                                                            // GetCloudAccounts(1, pageSize, status, serviceId, accountType, null);
                                                                            setSearchIconFlag(!searchIconFlag);
                                                                        }}>
                                                                        <span className="closebtn">
                                                                            &nbsp;
                                                                        </span>
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            }
                                                            {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                setShowFilterBox(!showFilterBox);
                                                            }}></span>}
                                                            {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                            <>
                                                                <Form.Group
                                                                    className="form-group col-md-5 col-lg-4 ml-3 mb-0 download-btn"
                                                                    controlId="downloadId"
                                                                >
                                                                    <NavDropdown title="Download Report" id="cloud-service-dropdown"
                                                                        className={emptyCustomerFlag ? "disabled-download-report servcies-dropdown" : "servcies-dropdown"} disabled={emptyCustomerFlag}>
                                                                        <NavDropdown.Item
                                                                            key="CSPReport"
                                                                            id="CSPReport"
                                                                            value="Download Microsoft CSP Report"
                                                                            className=""
                                                                            onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_CSP_REPORT_CSV, "MicrosoftTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                            Download Microsoft CSP Report
                                                                        </NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            key="GWSReport"
                                                                            id="GWSReport"
                                                                            value="Download Google Workspace Report"
                                                                            className=""
                                                                            onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_GWS_REPORT_CSV, "GoogleWorkspaceTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                            Download Google Workspace Report
                                                                        </NavDropdown.Item>
                                                                    </NavDropdown>
                                                                </Form.Group>
                                                            </>
                                                        </Col>
                                                    </Row>
                                                    <>
                                                        {showFilterBox &&
                                                            <ManagementConsoleFilterBox serviceId={serviceId} setServiceId={setServiceId} GetCloudAccounts={GetCloudAccounts} pageNo={pageNo} setPageNo={setPageNo}
                                                                pageSize={pageSize} searchValueTable={searchValueTable} status={status} setStatus={setStatus} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                                                                accountType={accountType} setAccountType={setAccountType} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} accountTypeLastUsed={accountTypeLastUsed}
                                                                setAccountTypeLastUsed={setAccountTypeLastUsed} setServiceIdLastUsed={setServiceIdLastUsed} serviceIdLastUsed={serviceIdLastUsed} Logout={props.Logout}
                                                            />
                                                        }
                                                    </>
                                                </Col>

                                                {/* Show only in Mobile */}
                                                <Col className="px-1 position-relative d-md-none d-block mt-3 mb-2" md={12}>
                                                    <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                        <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative  search-filter-block">
                                                            <InputGroup className="search-input-box">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                        onClick={() => {
                                                                            setPageNo(1);
                                                                            // GetCloudAccounts(1, pageSize, status, serviceId, accountType, searchValueTable);
                                                                        }}
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search for Company / Customer / Service"
                                                                    aria-label="Search for Company / Customer / Service"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setPageNo(1);
                                                                        setSearchValueTable("");
                                                                        // GetCloudAccounts(1, pageSize, status, serviceId, accountType, null);
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>

                                                            {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                setShowFilterBox(!showFilterBox);
                                                            }}></span>}
                                                            {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                            <>
                                                                <Form.Group
                                                                    className="form-group col-md-5 col-lg-4 ml-3 mb-0 download-btn"
                                                                    controlId="downloadId"
                                                                >
                                                                    <NavDropdown title="Download Report" id="cloud-service-dropdown"
                                                                        className="servcies-dropdown">
                                                                        <NavDropdown.Item
                                                                            key="CSPReport"
                                                                            id="CSPReport"
                                                                            value="Download Microsoft CSP Report"
                                                                            className=""
                                                                            onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_CSP_REPORT_CSV, "MicrosoftTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                            Download Microsoft CSP Report
                                                                        </NavDropdown.Item>
                                                                        <NavDropdown.Item
                                                                            key="GWSReport"
                                                                            id="GWSReport"
                                                                            value="Download Google Workspace Report"
                                                                            className=""
                                                                            onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_GWS_REPORT_CSV, "GoogleWorkspaceTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                            Download Google Workspace Report
                                                                        </NavDropdown.Item>
                                                                    </NavDropdown>
                                                                </Form.Group>
                                                            </>
                                                        </Col>
                                                    </Row>
                                                    <>
                                                        {showFilterBox &&
                                                            <ManagementConsoleFilterBox serviceId={serviceId} setServiceId={setServiceId} GetCloudAccounts={GetCloudAccounts} pageNo={pageNo} setPageNo={setPageNo}
                                                                pageSize={pageSize} searchValueTable={searchValueTable} status={status} setStatus={setStatus} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                                                                accountType={accountType} setAccountType={setAccountType} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} accountTypeLastUsed={accountTypeLastUsed}
                                                                setAccountTypeLastUsed={setAccountTypeLastUsed} setServiceIdLastUsed={setServiceIdLastUsed} serviceIdLastUsed={serviceIdLastUsed} Logout={props.Logout}
                                                            />
                                                        }
                                                    </>
                                                </Col>
                                            </Row>
                                            <div className="management-console-no-data-customers mt-5 mb-5">
                                                <img src={NoCustomer} className="empty-customers-table-svg" />
                                                <h2 className="mt-4 mb-3">No Customers Found</h2>
                                                {!(status || serviceId || accountType || searchValueTable) && <><p className={isDisabledCustomer ? "mb-3 d-none" : "mb-3"}>Please create new customer to view customers.</p>
                                                    <Button className={isDisabledCustomer ? "btn btn-lg btn-primary px-3 d-none" : "btn btn-lg btn-primary px-3"} onClick={() => navigate("/my-customers/add-customer")}>Create Customer</Button></>}
                                            </div>
                                        </>
                                    }
                                    {serverErrorCustomerFlag &&
                                        <div className="management-console-no-data-customers mt-5 mb-5">
                                            <img src={serverErrorCustomer} className="empty-customers-table-svg" />
                                            <h2 className="mt-4 mb-3 text-center">Facing issues in reaching servers, Try <br /> again later.</h2>
                                        </div>
                                    }
                                    {!emptyCustomerFlag && !serverErrorCustomerFlag && <>
                                        <Row className="mt-2">
                                            <Col className="d-flex align-items-center" md={3}>
                                                <p className="mb-0">Customers ({totalCustomerCount})</p>
                                            </Col>
                                            {/* Show only in desktop and Tablet */}
                                            <Col className="px-1 position-relative d-md-block d-none" md={9}>
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative  search-filter-block">
                                                        {!loading && !searchIconFlag &&
                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                        }
                                                        {!loading && searchIconFlag &&
                                                            <InputGroup className="search-input-box">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                        onClick={() => {
                                                                            setPageNo(1);
                                                                            // GetCloudAccounts(1, pageSize, status, serviceId, accountType, searchValueTable)
                                                                        }}
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search for Company / Customer / Service"
                                                                    aria-label="Search for Company / Customer / Service"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => {
                                                                        if (e.target.value.length === 0) {
                                                                            setPageNo(1);
                                                                            setSearchValueTable(null);
                                                                            // GetCloudAccounts(1, pageSize, status, serviceId, accountType, null)
                                                                        }
                                                                        else {
                                                                            setSearchValueTable(e.target.value)
                                                                        }
                                                                    }}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setPageNo(1);
                                                                        setSearchValueTable("");
                                                                        // GetCloudAccounts(1, pageSize, status, serviceId, accountType, null);
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }
                                                        {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                        <>
                                                            <Form.Group
                                                                className="form-group col-md-5 col-lg-4 ml-3 mb-0 download-btn"
                                                                controlId="downloadId"
                                                            >
                                                                <NavDropdown title="Download Report" id="cloud-service-dropdown"
                                                                    className={emptyCustomerFlag ? "disabled-download-report servcies-dropdown" : "servcies-dropdown"} disabled={emptyCustomerFlag}>
                                                                    <NavDropdown.Item
                                                                        key="CSPReport"
                                                                        id="CSPReport"
                                                                        value="Download Microsoft CSP Report"
                                                                        className=""
                                                                        onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_CSP_REPORT_CSV, "MicrosoftTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                        Download Microsoft CSP Report
                                                                    </NavDropdown.Item>
                                                                    <NavDropdown.Item
                                                                        key="GWSReport"
                                                                        id="GWSReport"
                                                                        value="Download Google Workspace Report"
                                                                        className=""
                                                                        onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_GWS_REPORT_CSV, "GoogleWorkspaceTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                        Download Google Workspace Report
                                                                    </NavDropdown.Item>
                                                                </NavDropdown>
                                                            </Form.Group>
                                                        </>
                                                    </Col>
                                                </Row>
                                                <>
                                                    {showFilterBox &&
                                                        <ManagementConsoleFilterBox serviceId={serviceId} setServiceId={setServiceId} GetCloudAccounts={GetCloudAccounts} pageNo={pageNo} setPageNo={setPageNo}
                                                            pageSize={pageSize} searchValueTable={searchValueTable} status={status} setStatus={setStatus} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                                                            accountType={accountType} setAccountType={setAccountType} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} accountTypeLastUsed={accountTypeLastUsed}
                                                            setAccountTypeLastUsed={setAccountTypeLastUsed} setServiceIdLastUsed={setServiceIdLastUsed} serviceIdLastUsed={serviceIdLastUsed} Logout={props.Logout}
                                                        />
                                                    }
                                                </>
                                            </Col>

                                            {/* Show only in Mobile */}
                                            <Col className="px-1 position-relative d-md-none d-block mt-3 mb-2" md={12}>
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative  search-filter-block">
                                                        <InputGroup className="search-input-box">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                    onClick={() => {
                                                                        setPageNo(1);
                                                                        // GetCloudAccounts(1, pageSize, status, serviceId, accountType, searchValueTable);
                                                                    }}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search for Company / Customer / Service"
                                                                aria-label="Search for Company / Customer / Service"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setPageNo(1);
                                                                    setSearchValueTable("");
                                                                    // GetCloudAccounts(1, pageSize, status, serviceId, accountType, null);
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>

                                                        {!loading && !filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {!loading && filterFlag && !emptyUserSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                        <>
                                                            <Form.Group
                                                                className="form-group col-md-5 col-lg-4 ml-3 mb-0 download-btn"
                                                                controlId="downloadId"
                                                            >
                                                                <NavDropdown title="Download Report" id="cloud-service-dropdown"
                                                                    className="servcies-dropdown">
                                                                    <NavDropdown.Item
                                                                        key="CSPReport"
                                                                        id="CSPReport"
                                                                        value="Download Microsoft CSP Report"
                                                                        className=""
                                                                        onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_CSP_REPORT_CSV, "MicrosoftTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                        Download Microsoft CSP Report
                                                                    </NavDropdown.Item>
                                                                    <NavDropdown.Item
                                                                        key="GWSReport"
                                                                        id="GWSReport"
                                                                        value="Download Google Workspace Report"
                                                                        className=""
                                                                        onClick={() => DownloadAction(GET_CLOUD_ACCOUNTS_DOWNLOAD_GWS_REPORT_CSV, "GoogleWorkspaceTenantReport__" + file_date_suffix + ".csv", "csv")}>
                                                                        Download Google Workspace Report
                                                                    </NavDropdown.Item>
                                                                </NavDropdown>
                                                            </Form.Group>
                                                        </>
                                                    </Col>
                                                </Row>
                                                <>
                                                    {showFilterBox &&
                                                        <ManagementConsoleFilterBox serviceId={serviceId} setServiceId={setServiceId} GetCloudAccounts={GetCloudAccounts} pageNo={pageNo} setPageNo={setPageNo}
                                                            pageSize={pageSize} searchValueTable={searchValueTable} status={status} setStatus={setStatus} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                                                            accountType={accountType} setAccountType={setAccountType} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed} accountTypeLastUsed={accountTypeLastUsed}
                                                            setAccountTypeLastUsed={setAccountTypeLastUsed} setServiceIdLastUsed={setServiceIdLastUsed} serviceIdLastUsed={serviceIdLastUsed} Logout={props.Logout}
                                                        />
                                                    }
                                                </>
                                            </Col>
                                        </Row>
                                        {
                                            isInitialLoading && <div className="management-console-loading-component text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>
                                        }

                                        {!isInitialLoading &&
                                            accountDetails.map(account => {
                                                return (
                                                    <div className="details-box p-3 product-box-outer">
                                                        <Row>
                                                            <Col md={4}>
                                                                <Col className="product-box-name">
                                                                    <div>
                                                                        <h3>{account.customerCompanyName}</h3>
                                                                        <p className="text-regular text-normal">{account.firstName} {account.lastName}</p>
                                                                    </div>
                                                                </Col>
                                                            </Col>

                                                            <Col md={8} className="mgmt-console-products-panel">

                                                                {
                                                                    account.accountDetails.length > 5 &&
                                                                    <Accordion>
                                                                        <Accordion.Item eventKey="0">
                                                                            {/* Repeat this loop for till 5 records */}
                                                                            <>
                                                                                <Accordion.Header>
                                                                                    {
                                                                                        account.accountDetails.slice(0, 5).map(subAccount => {
                                                                                            return (

                                                                                                <div className="product-box">
                                                                                                    <div className="product-box-inner" onClick={() => {
                                                                                                        if (subAccount.integrationCode == "googleworkspace") {
                                                                                                            navigate("/management-console/gws", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                        }
                                                                                                        else if (subAccount.integrationCode == "microsoftazure") {
                                                                                                            navigate("/management-console/azure", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                        }
                                                                                                        else if (subAccount.integrationCode == "GCP") {
                                                                                                            navigate("/management-console/gcp", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                        }
                                                                                                        else if (subAccount.integrationCode == "microsoftsaas") {
                                                                                                            navigate("/management-console/csp", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                        }
                                                                                                        else if (subAccount.integrationCode == "softlayer") {
                                                                                                            navigate("/management-console/ibm", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                        }
                                                                                                        else if (subAccount.integrationCode == "cloudcare") {
                                                                                                            navigate("/management-console/avast", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                        }
                                                                                                        else {
                                                                                                            navigate("/management-console/acronis", { state: { customerId: account.externalCustomerCompanyCode, resellerId: resellerId } })
                                                                                                        }
                                                                                                    }
                                                                                                    }>
                                                                                                        <div>
                                                                                                            <img src={
                                                                                                                subAccount.integrationCode == "googleworkspace" ? (
                                                                                                                    gwsProductImage
                                                                                                                ) : subAccount.integrationCode == "GCP" ? (
                                                                                                                    gcpProductImage
                                                                                                                ) : subAccount.integrationCode == "microsoftazure" ? (
                                                                                                                    azureProductImage
                                                                                                                ) : subAccount.integrationCode == "cloudcare" ? (
                                                                                                                    avastProductImage
                                                                                                                ) : subAccount.integrationCode == "microsoftsaas" ? (
                                                                                                                    cspProductImage
                                                                                                                ) : subAccount.integrationCode == "softlayer" ? (
                                                                                                                    ibmProductImage
                                                                                                                ) : acronisProductImage
                                                                                                            } />
                                                                                                            <br />
                                                                                                            <span className="status-active"></span>&nbsp;
                                                                                                            <span className="py-1 text-muted"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Accordion.Header>
                                                                            </>

                                                                            {/* Repeat this loop for from 6th record */}

                                                                            <Accordion.Body>
                                                                                {
                                                                                    account.accountDetails.slice(5).map(subAccount => {
                                                                                        return (
                                                                                            <div className="product-box">
                                                                                                <div className="product-box-inner" onClick={() => {
                                                                                                    if (subAccount.integrationCode == "googleworkspace") {
                                                                                                        navigate("/management-console/gws", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId} })
                                                                                                    }
                                                                                                    else if (subAccount.integrationCode == "microsoftazure") {
                                                                                                        navigate("/management-console/azure", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId} })
                                                                                                    }
                                                                                                    else if (subAccount.integrationCode == "GCP") {
                                                                                                        navigate("/management-console/gcp", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                    }
                                                                                                    else if (subAccount.integrationCode == "microsoftsaas") {
                                                                                                        navigate("/management-console/csp", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId} })
                                                                                                    }
                                                                                                    else if (subAccount.integrationCode == "softlayer") {
                                                                                                        navigate("/management-console/ibm", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                                    }
                                                                                                    else if (subAccount.integrationCode == "cloudcare") {
                                                                                                        navigate("/management-console/avast", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId} })
                                                                                                    }
                                                                                                    else {
                                                                                                        navigate("/management-console/acronis", { state: { customerId: account.externalCustomerCompanyCode,resellerId: resellerId } })
                                                                                                    }
                                                                                                }
                                                                                                }>
                                                                                                    <div>
                                                                                                        <img src={
                                                                                                            subAccount.integrationCode == "googleworkspace" ? (
                                                                                                                gwsProductImage
                                                                                                            ) : subAccount.integrationCode == "GCP" ? (
                                                                                                                gcpProductImage
                                                                                                            ) : subAccount.integrationCode == "microsoftazure" ? (
                                                                                                                azureProductImage
                                                                                                            ) : subAccount.integrationCode == "cloudcare" ? (
                                                                                                                avastProductImage
                                                                                                            ) : subAccount.integrationCode == "microsoftsaas" ? (
                                                                                                                cspProductImage
                                                                                                            ) : subAccount.integrationCode == "softlayer" ? (
                                                                                                                ibmProductImage
                                                                                                            ) : acronisProductImage
                                                                                                        } />
                                                                                                        <br />
                                                                                                        <span className="status-active"></span>&nbsp;
                                                                                                        <span className="py-1 text-muted">Active</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Accordion.Body>

                                                                        </Accordion.Item>
                                                                    </Accordion>
                                                                }
                                                                {
                                                                    account.accountDetails.length < 6 &&
                                                                    <div className="product-box">
                                                                        {
                                                                            account.accountDetails.slice(0, 5).map(subAccount => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="product-box-inner" onClick={() => {
                                                                                            if (subAccount.integrationCode == "googleworkspace") {
                                                                                                navigate("/management-console/gws", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                            else if (subAccount.integrationCode == "microsoftazure") {
                                                                                                navigate("/management-console/azure", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                            else if (subAccount.integrationCode == "GCP") {
                                                                                                navigate("/management-console/gcp", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                            else if (subAccount.integrationCode == "microsoftsaas") {
                                                                                                navigate("/management-console/csp", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                            else if (subAccount.integrationCode == "softlayer") {
                                                                                                navigate("/management-console/ibm", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                            else if (subAccount.integrationCode == "cloudcare") {
                                                                                                navigate("/management-console/avast", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                            else {
                                                                                                navigate("/management-console/acronis", { state: { customerId: account.externalCustomerCompanyCode ,resellerId: resellerId } })
                                                                                            }
                                                                                        }
                                                                                        }>
                                                                                            <div>
                                                                                                <img src={
                                                                                                    subAccount.integrationCode == "googleworkspace" ? (
                                                                                                        gwsProductImage
                                                                                                    ) : subAccount.integrationCode == "GCP" ? (
                                                                                                        gcpProductImage
                                                                                                    ) : subAccount.integrationCode == "microsoftazure" ? (
                                                                                                        azureProductImage
                                                                                                    ) : subAccount.integrationCode == "cloudcare" ? (
                                                                                                        avastProductImage
                                                                                                    ) : subAccount.integrationCode == "microsoftsaas" ? (
                                                                                                        cspProductImage
                                                                                                    ) : subAccount.integrationCode == "softlayer" ? (
                                                                                                        ibmProductImage
                                                                                                    ) : acronisProductImage
                                                                                                } />
                                                                                                <br />
                                                                                                <span className=
                                                                                                    {subAccount.nepheleStatus === "ACTIVE" ? 'status-active' : (subAccount.nepheleStatus === "CANCEL") ? 'status-failed' : (subAccount.nepheleStatus === "PENDING") ? 'status-pending' : 'status-suspended'}></span>
                                                                                                &nbsp;
                                                                                                <span className="py-1 text-muted">{subAccount.nepheleStatus === "ACTIVE" ? 'Active' : (subAccount.nepheleStatus === "CANCEL") ? 'Cancel' : (subAccount.nepheleStatus === "INACTIVE") ? 'Inactive' : (subAccount.nepheleStatus === "PENDING") ? 'Pending' : 'Suspended'}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                        {infinityScroll && <div className="text-center"><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading...</p></div>}
                                    </>}
                                </Col>
                            </div>
                        </div>
                    </div >
                </Container >}
                {
                    isDisabled && <AccessDenied />
                }
            </div >
        </>
    );
}

export default ManagementConsole;