import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import styles from './AvastProductDetail.module.scss';
import SearchDropdown from '../../../SearchDropdown';
import { AVAST_BILLING_TERM, GET_FLAVOR_CATEGORY, AVAST_LISTING_TABLE, CUSTOMER_LISTING_TABLE, DOWNLOAD_AVAST_LISTING_PRICE, ACCEPT_LOGIN_TERMS_AND_CONDITIONS } from '../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../Utils/API';
import Popup from 'reactjs-popup';
import { XLg } from 'react-bootstrap-icons';
import { emailRegEx } from '../../../../../../GlobalComponents/RegExPatterns';
import CustomNumberSimpleInput from '../../../../../../GlobalComponents/CustomNumberSimpleInput';
import CustomNumberInput from '../../../../../../GlobalComponents/CustomNumberInput';
import CustomNumberSimpleInputDefaultValueReset from './LocalComponents';
import Toast from '../../../../../../GlobalComponents/Toast';

const AvastProductDetail = ({ productId, serviceId, subProduct, setToastError, setToastMessage, setSelectCustomerErrorState, customerId,setPlanSelectedFlag,isreadOnly, Logout }) => {

    const date = new Date();                                                                                            // for js date time function
    const [loading, setLoading] = useState(false);
    const [emptyData, setEmptyData] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [productDetailsData, setProductDetailsData] = useState(null);
    const [productDetailsDataAllPlans, setProductDetailsDataAllPlans] = useState(null);
    let AvastProductDetailColumnName = ["", "Plan", "Billing Term", "Quantity", ""];
    let ReadOnlyAvastProductDetailColumnName = ["Plan", "Billing Term", "Quantity", ""];
    let AvastProductDetailColumnNameReadOnlyData = ["Plan", "SKU", "RRP (Ex GST)", "Price (Inc GST)"];
    const [selectedData, setSelectedData] = useState([]);
    const [selectedProductsArray, setSelectedProductsArray] = useState([]); // Array to hold selected products

    const [email, setEmail] = useState("");
    const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] = useState(false);
    const [customerEmailDetail, setCustomerEmailDetail] = useState("");
    const productIds = [572, 573, 574];
    const [emailError, setEmailError] = useState(false);
    const [termsConditionConfirmationModalOpen, setTermsConditionConfirmationModalOpen] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") =="false" ? false : true);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);
    const [poNumber, setPoNumber] = useState(null);
    const [orderConfirmationModalOpen, setOrderConfirmationModalOpen] = useState(false);

    const alphanumericRegex = /^[a-zA-Z0-9]{0,25}$/; // RegEx for PO Number
    let AvastProductDetailOrderConfirmationColumn = ["Plan", "Billing Term", "RRP (Inc GST)", "Price (Ex GST)", "Quantity", "Sub-Total Price", "Delete"];                            // Columns of table for Suspend Legacy plan Action 
    const [selectedRangeData, setSelectedRangeData] = useState(null);
    const [quantityResetFlag, setQuantityResetFlag] = useState(false);

    // Search Value for Billing Term
    const [billingTermDefaultValue, setBillingTermDefaultValue] = useState("Monthly");
    const [billingTermSearchValue, setBillingTermSearchValue] = useState();
    const [billingTerm, setBillingTerm] = useState([]);
    const [billingTermFiltered, setBillingTermFiltered] = useState(billingTerm);
    const updatedBillingTerm = [];
    const [billingTermId, setBillingTermId] = useState("monthly");

    // Search Value for Category
    const [categoryDefaultValue, setCategoryDefaultValue] = useState("Avast Antivirus");
    const [categorySearchValue, setCategorySearchValue] = useState();
    const [category, setCategory] = useState([]);
    const [categoryFiltered, setCategoryFiltered] = useState(category);
    const updatedCategory = [];
    const [categoryId, setCategoryId] = useState("Avast Antivirus");

    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall] = useState(null);
    const [newQuantity, setNewQuantity] = useState(null);
    const [storedPlanData, setStoredPlanData] = useState([]);  // use state to store selected plan data array
 
    const [ipAddress, setIpAddress] = useState(null);
    const userAgent = navigator.userAgent;
    const browserName = userAgent.match(/(Chrome|Safari|Firefox|Edge|Opera|Internet Explorer)\//);
    const browserVersion = userAgent.match(/\((.*?)\)/);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Billing Term Section Dropdown
    async function GetAllBillingTerm() {
        await api.get(AVAST_BILLING_TERM, config)
            .then(resp => {
                // console.log("Data billing term: ", resp.data);
                setBillingTerm(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    // console.log(error);
                }

            });
    };

    // Function to call all dropdown values in PlanName Section in Filter Box
    async function GetAllPlanName() {
        await api.get(`${GET_FLAVOR_CATEGORY}?productId=${productId}&serviceId=${serviceId}`, config)
            .then(resp => {
                // console.log("Data : ", resp.data);
                setCategory(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF pricing
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "pdf" || fileType === "zip") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Get Customer email Based on Customer ID
    async function GetCustomerDetails() {
        await api.get(CUSTOMER_LISTING_TABLE + "/" + customerId, config)
            .then(resp => {
                if (resp.status === 200) {
                    setCustomerEmailDetail(resp.data.itContactDetails.email);
                } else {
                    // setServerErrorCustomer(false);
                }

            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    // console.log(error);
                }

            });
    }

    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(productId, billingTermId, categoryId) {
        // setLoading(true);
        (productId === undefined || productId === null) ? productId = "" : productId = productId;
        (billingTermId === undefined || billingTermId === null) ? billingTermId = "" : billingTermId = billingTermId;
        (categoryId === undefined || categoryId === null) ? categoryId = "" : categoryId = categoryId;
        await api.get(`${AVAST_LISTING_TABLE}?productId=${productId}&billingTerm=${billingTermId}&planName=${categoryId}&customerCode=${customerId}&productIds=${productIds}`, config)
            .then(resp => {
                // setLoading(false);
                if (resp.status == 204) {
                    setEmptyData(true);
                }
                // handle success
                else {
                    setEmptyData(false);
                    // console.log("listing:", resp.data);
                    setProductDetailsData(resp.data);
                    setProductDetailsDataAllPlans(resp.data.plans);
                }
            })
            .catch(error => {
                // console.log(error);
                setLoading(false);
                setEmptyData(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setServerError(true);
                }
            });
    };

    // Function to update selected products array
    const UpdateSelectedProducts = (plan, planData) => {
        if (selectedProductsArray.includes(plan)) {
            setSelectedProductsArray(selectedProductsArray.map(name => name === plan ? plan : name));
        } else {
            setSelectedProductsArray([...selectedProductsArray, plan]);
        }
        // Store planData in an array
        setStoredPlanData(prevData => [...prevData, planData]);
    };

    function generateRandomIp() {
        // Generate random IP address components
        const octets = [];
        for (let i = 0; i < 4; i++) {
            octets.push(Math.floor(Math.random() * 256).toString());
        }
        
        // Join the octets with dots to form the IP address
        const ipAddress = octets.join(".");
        
        return ipAddress;
    }
    
    // Terms and Condition accept Popup Data
    async function TermsAndConditionAcceptFunction(browser,ipAddress) {
        (ipAddress === undefined || ipAddress === null || ipAddress === "null") ? ipAddress = generateRandomIp() : ipAddress = ipAddress;
        let body = {
            "browser": browser,
            "ipAddress": ipAddress,
            "module":"Cloud",
        }
        await api.post(ACCEPT_LOGIN_TERMS_AND_CONDITIONS,body, config).then((resp) => {
            // handle success
            // console.log(resp.data);
            localStorage.setItem("cloud_terms_and_conditions","true");
            setIsTermsChecked(true);
            })
            .catch((error) => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
            });
    }
    
    useEffect(() => {
        if(!isTermsChecked){
            fetch("https://api.ipify.org?format=json")
            .then(response => response.json())
            .then(data => setIpAddress(data.ip));
        }
    }, [isTermsChecked])

    useEffect(() => {
        if (productId && serviceId) {
            GetAllBillingTerm();
            GetAllPlanName();
        }
    }, [productId, serviceId])

    // dropdown data call
    useEffect(() => {
        if (billingTermId || categoryId) {
            setNewQuantity(null);
            setQuantityResetFlag(true);
            GetTableData(productId, billingTermId, categoryId)
        }
    }, [billingTermId, categoryId]);

    useEffect(() => {
        if (customerId) {
            GetCustomerDetails();
        }
    }, [customerId]);

    useEffect(() => {
        if (billingTermId) {
            setNewQuantity(null);
            setQuantityResetFlag(true);
            setSelectedProductsArray([]);
            setStoredPlanData([]);
            setSelectedData(null);
            // console.log("new quantity Avast billing term changed: ", newQuantity)
        }
    }, [billingTermId]);

    useEffect(() => {
        if (categoryId) {
            setNewQuantity(null);
            setQuantityResetFlag(true);
            // console.log("new quantity Avast category id changed: ", newQuantity)
        }
    }, [categoryId]);

    useEffect(() => {
        // console.log("new quantity Avast: ", newQuantity)
        // if (productDetailsData.billingTerm !== "monthly") {
        productDetailsDataAllPlans && productDetailsDataAllPlans.map((td) => {
            if (newQuantity >= 1 && newQuantity <= 250) {
                // console.log("1-250: ", productDetailsDataAllPlans[0])
                setSelectedRangeData(productDetailsDataAllPlans[0]);
                // console.log("data range:", selectedRangeData);
            }
            else if (newQuantity >= 251 && newQuantity <= 500) {
                // console.log("251-500: ")
                setSelectedRangeData(productDetailsDataAllPlans[1]);
            }
            else if (newQuantity >= 501 && newQuantity <= 1000) {
                // console.log("501-1000: ")
                setSelectedRangeData(productDetailsDataAllPlans[2]);
            }
            else if (newQuantity >= 1001 && newQuantity <= 9999) {
                // console.log("1001-9999: ")
                setSelectedRangeData(productDetailsDataAllPlans[3]);
            }
        })
        // }
        // else {
        //     setNewQuantity(null);
        // }
    }, [newQuantity]);

    useEffect(() => {
        if (customerId) {
            setSelectCustomerErrorState(false);
        }
    }, [customerId])

    useEffect(() => {
        if(selectedData!==null){
            setPlanSelectedFlag(true);
        }
        else{
            setPlanSelectedFlag(false);
        }
    }, [selectedData])

    return (
        <div>
            {/* email popup */}
            <Popup
                open={emailConfirmationModalOpen}
                onClose={() => { setEmailConfirmationModalOpen(false);setPoNumber(""); }}
                className="custom-modal custom-modal-sm custom-modal-ticket-details"
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            <h2>Customer selection</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={() => { { close(); } }}
                            />
                        </div>
                        <Container fluid>
                            <Row className="pb-3 pt-5">
                                <span className="mb-3 text-regular">You have not entered User Email id, please</span>
                                <span className="mb-3 text-regular"> enter it to proceed.</span>
                            </Row>
                            <Row className="py-3">
                                <Col>

                                    <Form.Label className="w-auto" htmlFor="email">User email ID:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        id="email"
                                        defaultValue={customerEmailDetail}
                                        aria-describedby="email"
                                        onChange={(e) => {
                                            if (emailRegEx.test(e.target.value)) {
                                                setCustomerEmailDetail(e.target.value);
                                                setEmailError(false);
                                            } else {
                                                setCustomerEmailDetail("");
                                                setEmailError(true);
                                            }
                                        }} />
                                    {emailError ? <span className="red text-small">Enter valid email ID</span>
                                        : <p className='empty-paragraph'></p>}

                                </Col>
                            </Row>
                            <Row className="mt-2 mb-4">
                                <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                    <Button
                                        className="px-4 mx-2"
                                        onClick={() => { setCustomerEmailDetail(""); setEmailConfirmationModalOpen(false); }}>Cancel</Button>
                                    {customerEmailDetail !== "" &&
                                        <Button
                                            className="px-4 mx-2"
                                            onClick={() => { setEmailConfirmationModalOpen(false); }}
                                        >
                                            Proceed
                                        </Button>}
                                    {customerEmailDetail === "" &&
                                        <Button
                                            variant="light"
                                            className="px-4 mx-2"
                                            disabled
                                        >
                                            Proceed
                                        </Button>}
                                </div>
                            </Row>
                        </Container>
                    </div>
                )}
            </Popup>

            {/* terms and condition popup */}
            <Popup
                open={termsConditionConfirmationModalOpen}
                onClose={() => { setTermsConditionConfirmationModalOpen(false); }}
                className="custom-modal custom-modal-sm custom-modal-ticket-details"
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            <h2>Terms & conditions</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={() => { { close(); } }}
                            />
                        </div>
                        <Container >
                            <ul >
                                <li className='mb-2'>
                                    GOVT-NFP orders will be reviewed by Synnex before processing the Order.
                                </li>
                                <li className='mb-2'>
                                    The general turn-around is 2 to 3 business days.
                                </li>
                                <li className='mb-2'>
                                    Pricing is based cumulated usage across all your customer accounts for every offer.
                                </li>
                                <li className='mb-2'>
                                    Monthly plans are billed based on the number of devices the service has been enabled in.
                                </li>
                                <li className='mb-2'>
                                    Prepaid plans are invoiced in the next billing cycle.
                                </li>
                                <li className='mb-2'>
                                    Avast Cloud Care not compatible with Mac.
                                </li>
                            </ul>
                        </Container>
                    </div>

                )}
            </Popup>

            {/* order confirmation popup */}
            <Popup
                open={orderConfirmationModalOpen}
                onClose={() => { setOrderConfirmationModalOpen(false);  setQuantityResetFlag(true); }}
                className="custom-modal custom-modal-xl custom-modal-ticket-details"
                closeOnDocumentClick={!confirmLoadingFlag}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            <h2>Order Confirmation - Cloud Provision</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                            />
                        </div>
                        <Container fluid>
                            <Row className="pb-3 pt-5">
                                <Col lg={8}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Customer Name: <span className="text-regular">Customer Name</span></h3>
                                </Col>
                                <Col lg={4}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Cloud Service: <span className="text-regular">Avast cloudcare</span></h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {selectedData &&
                                        <Table className={`${styles['csp-change-status-core-table']}`}
                                            responsive="md">
                                            <thead className="sticky-top">
                                                <tr>
                                                    {AvastProductDetailOrderConfirmationColumn.map(th => {
                                                        return (<th className={`${(th === "RRP (Inc GST)" || th === "Price (Ex GST)" || th === "Sub-Total Price") ? "text-right" : ""} ${(th === "Delete") ? "text-center" : ""}`}>
                                                            {th}</th>)
                                                    })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    selectedRangeData && billingTermId !== "monthly" &&
                                                    <tr className={styles['vertical-align-middle']}>
                                                        <td className={styles['big-width']}>{selectedRangeData.planName}</td>
                                                        <td >{productDetailsData.billingTerm}</td>
                                                        {selectedRangeData.billingTerm === "monthly" ? <td className='text-right'>Usage based</td> : <td className='text-right'>${selectedRangeData.rrp}</td>}
                                                        <td className='text-right'>${selectedRangeData.price}</td>
                                                        <td>
                                                            {/* {productDetailsDataAllPlans.quantity} */}
                                                            <CustomNumberSimpleInputDefaultValueReset
                                                                defaultValue={newQuantity ? newQuantity : productDetailsData.quantity}
                                                                min={selectedRangeData.minQuantity}
                                                                max={selectedRangeData.maxQuantity}
                                                                setUpdatedQuanity={setNewQuantity}
                                                                refreshFlag={quantityResetFlag}
                                                                setRefreshFlag={setQuantityResetFlag}
                                                            />
                                                        </td>
                                                        <td className='text-right'>${newQuantity ? ((selectedRangeData.price) * newQuantity).toFixed(2)
                                                            : ((selectedRangeData.price)) * productDetailsData.quantity}</td>
                                                        <td className={`text-center ${styles['delete-column']}`}>
                                                            <span className="trash"
                                                            //   onClick={() => handleDeleteRow(index)}
                                                            >&nbsp;</span>
                                                        </td>
                                                    </tr>
                                                }

                                                {storedPlanData && billingTermId === "monthly" &&
                                                    storedPlanData.map((td, index) => {
                                                        return (
                                                            <tr key={index} className={styles['vertical-align-middle']}>
                                                                <td className={styles['big-width']}>{td.planName}</td>
                                                                <td >Monthly</td>
                                                                <td className='text-right'>Usage based</td>
                                                                <td className='text-right'>Usage based</td>
                                                                <td>Usage based</td>
                                                                <td className='text-right'>Usage based</td>
                                                                <td className={`text-center ${styles['delete-column']}`}>
                                                                    <span className="trash"
                                                                    //   onClick={() => handleDeleteRow(index)}
                                                                    >&nbsp;</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>
                                        </Table>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col md={8}>
                                    {<p className='empty-paragraph mb-12'></p>}
                                    <p className='mb-0 text-small'>
                                        Indicative pricing only.
                                    </p>
                                    <p className='text-small'>
                                        Pricing tier and billing is based on the aggregate of usage (for every offer) across all your customers.
                                    </p>

                                </Col>
                                {billingTermId !== "monthly" &&
                                    <Col md={4}>
                                        <div className={`table-responsive col-12 my-1 ${styles['csp-popup-table']}`}>
                                            <Table className={`table w-100 ${styles['csp-change-status-core-table']}`}>
                                                <tr>
                                                    <td colSpan={6} className={`text-right`}>
                                                        <div>
                                                            <span>Base price (ex. GST): </span>
                                                            <span className={`text-right ${styles['total-block-right']}`}>
                                                                {selectedRangeData &&
                                                                    <> ${newQuantity ? ((selectedRangeData.price) * newQuantity).toFixed(2)
                                                                        : ((selectedRangeData.price)) * productDetailsData.quantity}</>}                                              </span><br />

                                                            <span>GST (10.00%):</span>
                                                            <span className={`text-right ${styles['total-block-right']}`}>
                                                                {/* ${gstAmount} */}
                                                            </span><br />
                                                        </div>
                                                        <span className={`${styles['total-block-row']}`}>
                                                            <span className="text-strong">Total (inc. GST):</span>
                                                            <span className={`text-right text-strong ${styles['total-block-right']}`}>
                                                                {/* ${totalWithGST} */}
                                                            </span>
                                                        </span>

                                                    </td>
                                                    <td className={`${styles['delete-column']}`}></td>
                                                </tr>
                                            </Table>
                                        </div>
                                    </Col>}
                            </Row>


                            <Row className="py-3">
                                <Col>

                                    <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="w-auto d-inline ml-3"
                                        id="inputPONumber"
                                        placeholder='Add PO Number'
                                        aria-describedby="inputPONumber"
                                        maxlength="25"
                                        value={poNumber}
                                        onChange={(e) => {
                                            alphanumericRegex.test(e.target.value)
                                                ? setPoNumber(e.target.value)
                                                : console.log("")
                                        }}
                                    />
                                    <Form.Text id="poNumber" />

                                </Col>
                            </Row>
                            <Row className="mt-2 mb-4">
                                <Col md={6}>

                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="termsCheckBox"
                                        label=""
                                        disabled
                                        checked={isTermsChecked}
                                        className="d-inline-block w-auto"
                                    />
                                    <Form.Label className="d-inline-block  w-auto mb-0">
                                        I agree to the <a href="/terms-and-conditions" target='_blank'  onClick={() => {if(!isTermsChecked){let browser = browserName[1] + " " + browserVersion[1];TermsAndConditionAcceptFunction(browser,ipAddress);}}}>terms & conditions</a> of Synnex cloud platform
                                    </Form.Label>

                                </Col>
                                <Col md={6}>
                                    <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                        {!confirmLoadingFlag && isTermsChecked && <>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => { setOrderConfirmationModalOpen(false);  setQuantityResetFlag(true); }}>Cancel</Button>
                                            <Button
                                                className="px-4 mx-2"
                                            // onClick={UpdateEditQuantity}
                                            >
                                                Confirm
                                            </Button>
                                        </>
                                        }
                                        {!confirmLoadingFlag && !isTermsChecked &&
                                            <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { setOrderConfirmationModalOpen(false);  setQuantityResetFlag(true); }}>Cancel
                                                </Button>
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2"
                                                    disabled
                                                >
                                                    Confirm
                                                </Button>
                                            </>
                                        }
                                        {confirmLoadingFlag &&
                                            <>
                                                <Button
                                                    variant="light"
                                                    disabled
                                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                >Cancel
                                                </Button>
                                                <Button
                                                    disabled
                                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                    variant="light"
                                                >
                                                    Processing . . .
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                )}
            </Popup>

            {<>
                <div className={`${styles['Avast-top-heading']}`}>

                    <Row className='ml-4 mt-4'>
                        <Col md={4}>
                            <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1">
                                <Form.Label>User email ID</Form.Label>
                                <Form.Control type="email" value={customerEmailDetail}
                                    onChange={(e) => {
                                        setCustomerEmailDetail(e.target.value)
                                        if (emailRegEx.test(customerEmailDetail)) {
                                            setEmailError(false);
                                        } else {
                                            setEmailError(true);
                                        }
                                    }} />
                                {emailError && <span className="red text-small">Enter valid email ID</span>}

                            </Form.Group>
                        </Col>

                        <Col md={8} className='mt-9'>
                            <div className='text-right mr-3'>
                                <a className={`cpointer mr-9 ${styles['current-subscription-tag']}`} onClick={() => { setTermsConditionConfirmationModalOpen(true); }}>Terms & conditions</a>
                                {subProduct === "Commercial Offers" && <Button
                                    className="btn btn-lg btn-border-light px-4"
                                    variant="light"
                                    onClick={(e) => { DownloadAction(DOWNLOAD_AVAST_LISTING_PRICE, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_avast_price_list.pdf`, "pdf") }}
                                >
                                    Download price list
                                </Button>}
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className='mb-4 px-3'>
                    <Col md={6}>
                        <h2 className='notes-confirmation mt-7'>Plans</h2>
                    </Col>
                    <Col md={3} className={`${styles['product-plan-top-right-card']}`}>
                        <SearchDropdown
                            dropdownLabel="Plan name"
                            formGroupId="categoryId"
                            placeholder="Enter Plan name"
                            selectDefaultValue={categoryDefaultValue}
                            setSelectDefaultValue={setCategoryDefaultValue}
                            setOptionId={setCategoryId}
                            selectOptions={category}
                            selectOptionsFiltered={categoryFiltered}
                            setSelectOptionsFiltered={setCategoryFiltered}
                            selectSearchValue={categorySearchValue}
                            setSelectSearchValue={setCategorySearchValue}
                            updatedOptions={updatedCategory}
                            hasStaticOptions={false}
                            setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                        />
                    </Col>
                    <Col md={3} className={`${styles['product-plan-top-right-card']}`}>
                        <SearchDropdown
                            dropdownLabel="Billing term"
                            formGroupId="billingTermId"
                            placeholder="Enter Billing term"
                            selectDefaultValue={billingTermDefaultValue}
                            setSelectDefaultValue={setBillingTermDefaultValue}
                            setOptionId={setBillingTermId}
                            selectOptions={billingTerm}
                            selectOptionsFiltered={billingTermFiltered}
                            setSelectOptionsFiltered={setBillingTermFiltered}
                            selectSearchValue={billingTermSearchValue}
                            setSelectSearchValue={setBillingTermSearchValue}
                            updatedOptions={updatedBillingTerm}
                            hasStaticOptions={false}
                            setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            <Table className={`${styles['csp-change-status-core-table-1']}`}
                                responsive="md">
                                <thead className="sticky-top">
                                    <tr>
                                        {(isreadOnly ? ReadOnlyAvastProductDetailColumnName : AvastProductDetailColumnName).map(th => {
                                            return (<th>
                                                {th}</th>)
                                        })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productDetailsData &&
                                        <tr className={styles['vertical-align-middle']}>
                                            <td className={isreadOnly ? "d-none" : ""}><input type='radio'
                                                className='cpointer'
                                                name="productPlan"                               // same name should be there for checking radio
                                                checked={selectedProductsArray.includes(productDetailsData.planName)}
                                                id={productDetailsData.planName}
                                                onChange={() => {
                                                    UpdateSelectedProducts(productDetailsData.planName, productDetailsData);
                                                    setSelectedData(productDetailsData);
                                                }} /></td>
                                            <td>{productDetailsData.planName}</td>
                                            <td>{productDetailsData.billingTerm}</td>
                                            {billingTermId === "monthly" ? <td >Usage based </td> : <td >
                                                {/* component should be added here */}
                                                <CustomNumberSimpleInputDefaultValueReset
                                                    defaultValue={newQuantity ? newQuantity : productDetailsData.minQuantity}
                                                    min={productDetailsData.minQuantity}
                                                    max={productDetailsData.maxQuantity}
                                                    setUpdatedQuanity={setNewQuantity}
                                                    refreshFlag={quantityResetFlag}
                                                    setRefreshFlag={setQuantityResetFlag}
                                                />
                                            </td>}
                                            <td>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>

                <Container fluid className='mb-0'>
                    <Row>
                        <Col className='text-regular mb-4'>{categoryId} device details</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className={`mb-0`}
                                responsive="md">
                                <thead className="sticky-top">
                                    <tr>
                                        {AvastProductDetailColumnNameReadOnlyData.map(th => {
                                            return (<th className={(th === "RRP (Ex GST)" || th === "Price (Inc GST)") ? "text-right" : ""}>
                                                {th}</th>)
                                        })
                                        }
                                    </tr>
                                </thead>
                                <tbody>

                                    {productDetailsDataAllPlans && productDetailsDataAllPlans.map(tr => {
                                        return (
                                            <tr>
                                                <td className={styles['small-width']}>{tr.planName}</td>
                                                <td>{tr.skuId}</td>
                                                <td className='text-right'>${tr.price}</td>
                                                <td className='text-right'>${tr.rrp}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                </Container>

                {selectedData && <div className={`${styles['productlisting-main-table']} mb-0 pt-3`}>
                    <div className={`${styles['csp-purchase-addon-count-row-inner']} mb-0`}>
                        <Row>
                            <Col md={4} className="d-flex pt-0 pb-0 align-items-center">
                                <p className="m-0 p-0 ml-7 text-small">{selectedProductsArray.length} plan selected</p>
                            </Col>
                            <Col md={8} className='pt-0 pb-0'>
                                <div className="btn-wrapper justify-content-end d-flex mr-5">
                                    <Button
                                        variant="light"
                                        className="px-4 pt-0 pb-0 btn-border-light w-md-auto col-xs-12"
                                        onClick={() => { setSelectedData(null); setQuantityResetFlag(true); setNewQuantity(productDetailsData.minQuantity); setSelectedProductsArray([]) }}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        className="px-4 pt-0 pb-0  w-md-auto col-xs-12"
                                        onClick={() => {

                                            // if (customerEmailDetail === "") {
                                            //     setEmailConfirmationModalOpen(true)
                                            // }
                                            // else {
                                            //     if (customerId) {
                                            //         setSelectCustomerErrorState(false);
                                            //         setOrderConfirmationModalOpen(true);
                                            //     }
                                            //     else {
                                            //         setSelectCustomerErrorState(true);
                                            //         window.scrollTo(0, 0);
                                            //     }
                                            //order confirmation popup                                           
                                            // if (productDetailsData.billingTerm === "monthly") {
                                            //     setSelectedRangeData(productDetailsData);
                                            //     console.log("monthly data", productDetailsData);
                                            // }                                            
                                            // }

                                            // setOrderConfirmationModalOpen(true);
                                        }}

                                    >
                                        Proceed
                                    </Button>


                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </>}
        </div>
    )
}

export default AvastProductDetail
