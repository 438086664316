import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation,useNavigate } from "react-router-dom"
import styles from './cloudInvoices.module.scss'
import { createTheme } from 'react-data-table-component';
import { api } from "../../../../Utils/API";
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import {
    GET_SERVICES_ALL, GET_CUSTOMERS_LIST, GET_STATUS_LIST,
    CLOUD_INVOICE_LISTING_TABLE, CLOUD_INVOICE_DOWNLOAD_CSV_SUMMARY, CLOUD_INVOICE_DOWNLOAD_CSV_RECON,
    CLOUD_INVOICE_DOWNLOAD_PDF_SUMMARY,
    RESELLER_API
} from '../../../../Utils/GlobalConstants';
import CloudInvoiceFilterBox from './LocalComponents/FilterBox';
import { useMsal } from '@azure/msal-react';
import Toast from '../../../../GlobalComponents/Toast';
import AccessDenied from '../../../../GlobalComponents/EmptyStates/AccessDenied';
import LazyTable from './LocalComponents/LazyTable';
import ResellerNavdropdown from '../../../../GlobalComponents/ResellerNavdropdown';
 
const InvoiceDetailsListing = (props) => {
    const navigate = useNavigate();                                                     // to use navigate function from react router dom    
    const date = new Date();                                                            // for js date time function
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Billing") ? true : false);

    const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState((searchParams.get('searchValueTable') && searchParams.get('searchValueTable')!=="null") ? searchParams.get('searchValueTable') : null);                     // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                                // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                          // showing filter box
    const searchInputRef = useRef();

    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown

    // Table Parameters
    const [pageNo, setPageNo] = useState(parseInt(searchParams.get('pageNo')) || 1);                                            // table active page number
    const [pageSize, setPageSize] = useState(parseInt(searchParams.get('pageSize')) ||  15);                                        // table active page size
    const [totalRows, setTotalRows] = useState(0);                                      // table total count of data from api 
    const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                             // data state used to display data in react data table component
    let ColumnName = ["Invoice No.", "Cloud Service",                                   // table headers used to make object of react data table component headers
        "Summary No.", "Customer", "Invoice Date",
        "Due Date", "Amount", "Balance Amount", "Status", "Actions"];
    const [initialLoading, setInitialLoading] = useState(true);                         // loader for table

    const [serverErrorCloudInvoice, setServerErrorCloudInvoice] = useState(false);      // server error check state in table during api call to load data
    const [emptyCloudInvoice, setEmptyCloudInvoice] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCloudInvoiceFilters, setEmptyCloudInvoiceFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCloudInvoiceSearch, setEmptyCloudInvoiceSearch] = useState(false);      // empty search response check state in table during api call to load data
    const [emptyCloudSummaryReseller, setEmptyCloudSummaryReseller] = useState(false);                  // empty table check state in table during api call to load data for reseller dropdown

    const [isDescInvoiceNumber, setIsDescInvoiceNumber] = useState(searchParams.get('sortCol') === "invoiceNumber" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);               // sort state check for invoice number column
    const [isDescCloudService, setIsDescCloudService] = useState(searchParams.get('sortCol') === "serviceName" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                 // sort state check for cloud service column
    const [isDescInvoiceDate, setIsDescInvoiceDate] = useState(searchParams.get('sortCol') === "invoiceDate" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                   // sort state check for invoice date column
    const [isDescSummaryNumber, setIsDescSummaryNumber] = useState(searchParams.get('sortCol') === "summaryNumber" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);               // sort state check for summary number column
    const [isDescCustomer, setIsDescCustomer] = useState(searchParams.get('sortCol') === "customerName" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                         // sort state check for customer column
    const [isDescDueDate, setIsDescDueDate] = useState(searchParams.get('sortCol') === "dueDate" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                           // sort state check for due date column
    const [isDescStatus, setIsDescStatus] = useState(searchParams.get('sortCol') === "status" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                             // sort state check for status column

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);
    const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading

    // Get Table Data Function Parameters
    const [serviceId, setServiceId] = useState(searchParams.get('serviceId') || null);                                   // getTableData() service id
    const [invoiceDateFrom, setInvoiceDateFrom] = useState(searchParams.get('invoiceDateFrom') || null);                       // getTableData() invoice from date
    const [invoiceDateTo, setInvoiceDateTo] = useState(searchParams.get('invoiceDateTo') || null);                           // getTableData() invoice to date
    const [dueDateFrom, setDueDateFrom] = useState(searchParams.get('dueDateFrom') || null);                               // getTableData() due from date
    const [dueDateTo, setDueDateTo] = useState(searchParams.get('dueDateTo') || null);                                   // getTableData() due to date
    const [sortCol, setSortCol] = useState(searchParams.get('sortCol') || "invoiceNumber");                            // getTableData() sorting column name
    const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || "DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [customerId, setCustomerId] = useState((searchParams.get('customerId') && searchParams.get('customerId')!=="null") ? searchParams.get('customerId') :  ((location.state?.customerId ? location.state?.customerId : null)));                                 // getTableData() customer id
    const [status, setStatus] = useState(searchParams.get('status') || null);                                         // getTableData() status

    const [invoiceServiceLastUsedDate, setInvoiceServiceLastUsedDate]
        = useState(searchParams.get('invoiceServiceLastUsedDate') || "default");                                                        // service dropdown saved data after applying filters
    const [invoiceDateLastUsedDate, setInvoiceDateLastUsedDate]
        = useState(searchParams.get('invoiceDateLastUsedDate') || "default");                                                        // invoice date dropdown saved data after applying filters
    const [dueDateLastUsedDate, setDueDateLastUsedDate]
        = useState(searchParams.get('dueDateLastUsedDate') || "default");                                                        // due date dropdown saved data after applying filters 
    const [customerLastUsedDate, setCustomerLastUsedDate]
        = useState(searchParams.get('customerLastUsedDate') || "default");                                                        // customer dropdown saved data after applying filters
    const [statusLastUsedDate, setStatusLastUsedDate]
        = useState(searchParams.get('statusLastUsedDate') || "default");                                                        // status dropdown saved data after applying filters  
    const [customerData, setCustomerData] = useState(null);                             // data in customer dropdown in filter box

    // if custom was selected in due date and invoice date in filter box
    const [invoiceDateCustomFromLast, setInvoiceDateCustomFromLast]
        = useState(searchParams.get('invoiceDateCustomFromLast') || date.toISOString().slice(0, 10));                                  // invoice from date saved data after applying filter having custom selection
    const [invoiceDateCustomToLast, setInvoiceDateCustomToLast]
        = useState(searchParams.get('invoiceDateCustomToLast') || date.toISOString().slice(0, 10));                                  // invoice to date saved data after applying filter having custom selection
    const [dueDateCustomFromLast, setDueDateCustomFromLast]
        = useState(searchParams.get('dueDateCustomFromLast') || date.toISOString().slice(0, 10));                                  // due from date saved data after applying filter having custom selection
    const [dueDateCustomToLast, setDueDateCustomToLast]
        = useState(searchParams.get('dueDateCustomToLast') || date.toISOString().slice(0, 10));                                  // due from date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                            // toast message displaying success message
    const [toastError, setToastError] = useState(false);                                // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                               // toast message usestate

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

     // reseller dropdown for  staff users
    const [resellerData, setResellerData] = useState([]);                            // data in customer dropdown
    const [resellerNameDefaultValue, setResellerNameDefaultValue] = useState(searchParams.get('resellerNameDefaultValue') || "Resellers");
    const [resellerNameSearchValue, setResellerNameSearchValue] = useState();
    const [resellerName, setResellerName] = useState([]);
    const [resellerNameFiltered, setResellerNameFiltered] = useState(resellerName);
    const updatedResellerName = [];
    const [resellerId, setResellerId] = useState(searchParams.get('resellerId') || null);
    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(id, url, name, fileType) {
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(`${url + id}?requestedResellerCode=${resellerId}`, configuration)
            .then(resp => {
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

     // Function to call all dropdown values in Reseller Section in Filter Box
     async function GetAllResellers() {
        await api.get(RESELLER_API, config)
            .then(resp => {
                setResellerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(GET_SERVICES_ALL, config)
            .then(resp => {
                setServiceData([{ id: "0", name: 'All Services', integrationCode: 'default' }, ...resp.data]);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GET_STATUS_LIST, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status, appendData, isFilter) {
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (serviceId === undefined || serviceId === null || serviceId === "null") ? serviceId = "" : serviceId = serviceId;
        (invoiceDateFrom === undefined || invoiceDateFrom === null || invoiceDateFrom === "null") ? invoiceDateFrom = "" : invoiceDateFrom = invoiceDateFrom;
        (invoiceDateTo === undefined || invoiceDateTo === null || invoiceDateTo === "null") ? invoiceDateTo = "" : invoiceDateTo = invoiceDateTo;
        (dueDateFrom === undefined || dueDateFrom === null || dueDateFrom === "null") ? dueDateFrom = "" : dueDateFrom = dueDateFrom;
        (dueDateTo === undefined || dueDateTo === null || dueDateTo === "null") ? dueDateTo = "" : dueDateTo = dueDateTo;
        (customerId === undefined || customerId === null || customerId === "null") ? customerId = "" : customerId = customerId;
        (status === undefined || status === null || status === "null") ? status = "" : status = status;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;
        await api.get(`${CLOUD_INVOICE_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&invoiceNumber=${search}&serviceId=${serviceId}&customerId=${customerId}&status=${status}&startInvoiceDate=${invoiceDateFrom}&endInvoiceDate=${invoiceDateTo}&fromDueDate=${dueDateFrom}&toDueDate=${dueDateTo}&sortCol=${sortCol}&sortDir=${sortDir}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search === "" && serviceId === "" && invoiceDateFrom === "" && invoiceDateTo === "" && dueDateFrom === "" && dueDateTo === "" && customerId === "" && status === "" && resellerId === "") {
                        setEmptyCloudInvoice(true);
                    } else if ((serviceId != "" || invoiceDateFrom != "" || invoiceDateTo != "" || dueDateFrom != "" || dueDateTo != "" || customerId != "" || status != ""  || resellerId != "") && isFilter)  {
                        setEmptyCloudInvoiceFilters(true);
                    } else if (search != "" && !isFilter) {
                        setEmptyCloudInvoiceSearch(true);
                    } else {
                        setEmptyCloudSummaryReseller(true);
                    }
                    // console.log("Checking :",location.state?.customerId,isFilter);
                    
                }
                // handle success
                else {
                    setServerErrorCloudInvoice(false);
                    setEmptyCloudInvoice(false);
                    setEmptyCloudInvoiceFilters(false);
                    setEmptyCloudInvoiceSearch(false);
                    setEmptyCloudSummaryReseller(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Invoice No.": <span className={styles["cloud-invoice-color-underline"]} onClick={() => { navigate("/billing/cloud-invoice/invoice-detail", { state: { Id: td.invoiceNumber, summaryNumber: td.summaryNumber, resellerId: resellerId }, }); }} >{td.invoiceNumber}</span>, //"PICT00000235",
                                    "Cloud Service": td.serviceName,
                                    "Summary No.": td.summaryNumber,
                                    "Customer": <span title={td.customerName}>{td.customerName}</span>,
                                    "Invoice Date": td.invoiceDate,
                                    "Due Date": td.dueDate,
                                    "Amount": td.amount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.amount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.amount)).toFixed(2))}`,
                                    "Balance Amount": td.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.balanceAmount)).toFixed(2))}`,
                                    "Status": td.status === 'PAID' ? (
                                        <div className={`${styles['status-cloud-invoice']}`}>
                                            <span className="status-paid-new"></span>
                                            <div className="py-1 text-muted text-small">Paid</div>
                                        </div>
                                    ) : td.status === 'OUTSTANDING' ? (
                                        <div className={`${styles['status-cloud-invoice']}`}>
                                            <span className="status-outstanding-new"></span>
                                            <div className="py-1 text-muted text-small">Outstanding</div>
                                        </div>
                                    ) : td.status === 'OVERDUE' ? (
                                        <div className={`${styles['status-cloud-invoice']}`}>
                                            <span className="status-overdue"></span>
                                            <div className="py-1 text-muted text-small">Overdue</div>
                                        </div>
                                    ) :
                                        (
                                            td.status
                                        ),
                                    "Actions": <Dropdown drop={"start"}>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.invoiceNumber, CLOUD_INVOICE_DOWNLOAD_PDF_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.invoiceNumber}_invoice.pdf`, "pdf") }} id="1">Download PDF</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.invoiceNumber, CLOUD_INVOICE_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.invoiceNumber}_invoice.csv`, "csv") }} id="1">Download CSV</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.invoiceNumber, CLOUD_INVOICE_DOWNLOAD_CSV_RECON, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.invoiceNumber}_reconfile.csv`, "csv") }} id="1">Download Reconciliation</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            );
                    })
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Actions") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Invoice No.") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {(isDescInvoiceNumber === null && sortCol !=="invoiceNumber") && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceNumber(true); setSortCol("invoiceNumber"); setIsDescCloudService(null); setIsDescSummaryNumber(null); setIsDescCustomer(null); setIsDescInvoiceDate(null); setIsDescDueDate(null); setIsDescStatus(null); }}></span></>}
                                    {((isDescInvoiceNumber === null && sortCol ==="invoiceNumber" && sortDir === "DESC") || isDescInvoiceNumber === true) &&  <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescInvoiceNumber(false); setSortCol("invoiceNumber"); }}></span></>}
                                    {isDescInvoiceNumber === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceNumber(true); setSortCol("invoiceNumber"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Cloud Service") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescCloudService === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => {setScrollToDivTop(true);  setSortDir("DESC"); setIsDescCloudService(true); setSortCol("serviceName"); setIsDescInvoiceNumber(null); setIsDescSummaryNumber(null); setIsDescCustomer(null); setIsDescInvoiceDate(null); setIsDescDueDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescCloudService === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => {setScrollToDivTop(true);  setSortDir("ASC"); setIsDescCloudService(false); setSortCol("serviceName"); }}></span></>}
                                    {isDescCloudService === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCloudService(true); setSortCol("serviceName"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.2,
                            })
                        }
                        else if (td === "Summary No.") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescSummaryNumber === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescSummaryNumber(true); setSortCol("summaryNumber"); setIsDescInvoiceNumber(null); setIsDescCloudService(null); setIsDescCustomer(null); setIsDescInvoiceDate(null); setIsDescDueDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescSummaryNumber === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => {setScrollToDivTop(true);  setSortDir("ASC"); setIsDescSummaryNumber(false); setSortCol("summaryNumber"); }}></span></>}
                                    {isDescSummaryNumber === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => {setScrollToDivTop(true);  setSortDir("DESC"); setIsDescSummaryNumber(true); setSortCol("summaryNumber"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Customer") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescCustomer === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCustomer(true); setSortCol("customerName"); setIsDescInvoiceNumber(null); setIsDescCloudService(null); setIsDescSummaryNumber(null); setIsDescInvoiceDate(null); setIsDescDueDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescCustomer === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCustomer(false); setSortCol("customerName"); }}></span></>}
                                    {isDescCustomer === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCustomer(true); setSortCol("customerName"); }}></span></>}
                                </div>,
                                selector: row => <div className="">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Invoice Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescInvoiceDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("invoiceDate"); setIsDescInvoiceNumber(null); setIsDescCloudService(null); setIsDescSummaryNumber(null); setIsDescCustomer(null); setIsDescDueDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescInvoiceDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescInvoiceDate(false); setSortCol("invoiceDate"); }}></span></>}
                                    {isDescInvoiceDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescInvoiceDate(true); setSortCol("invoiceDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Due Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescDueDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDueDate(true); setSortCol("dueDate"); setIsDescInvoiceNumber(null); setIsDescCloudService(null); setIsDescSummaryNumber(null); setIsDescCustomer(null); setIsDescInvoiceDate(null); setIsDescStatus(null); }}></span></>}
                                    {isDescDueDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescDueDate(false); setSortCol("dueDate"); }}></span></>}
                                    {isDescDueDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDueDate(true); setSortCol("dueDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 0.7,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescStatus === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStatus(true); setSortCol("status"); setIsDescInvoiceNumber(null); setIsDescCloudService(null); setIsDescSummaryNumber(null); setIsDescCustomer(null); setIsDescInvoiceDate(null); setIsDescDueDate(null); }}></span></>}
                                    {isDescStatus === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => {setScrollToDivTop(true);  setSortDir("ASC"); setIsDescStatus(false); setSortCol("status"); }}></span></>}
                                    {isDescStatus === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStatus(true); setSortCol("status"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                grow: 0.5,
                            })
                        }
                        else if (td === "Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                                grow: 0.5,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                    setServerErrorCloudInvoice(true);
                }

            });
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if(searchValueTable === "null"){
            setSearchValueTable(null);
        }
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setResetPaginationToggle(true);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
                    setResetPaginationToggle(false);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setResetPaginationToggle(true);
                    GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
                    setResetPaginationToggle(false);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
      }, [searchValueTable])

       // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status,true))();

            return newPageNumber;
        });
    }

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '400px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',          // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',         // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px',        // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, serviceId, invoiceDateFrom, invoiceDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, customerId, status);
    }, [isDescSummaryNumber, isDescInvoiceDate, isDescCloudService, isDescDueDate, isDescInvoiceDate, isDescCustomer, isDescInvoiceNumber, isDescStatus])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/billing/invoice");
        GetAllServices();
        GetAllStatuses();
        { isCloudAdmin && GetAllResellers(); }
        if (customerData === null) {
            GetAllCustomers();
        }
    }, []);

     // useEffct to select reseller for staff user
     useEffect(() => {
        setSearchValueTable(null);
        setServiceId(null);
        setInvoiceDateFrom(null);
        setInvoiceDateTo(null);
        setDueDateFrom(null);
        setDueDateTo(null);
       if (invoiceDateLastUsedDate !== "default") {
            setInvoiceDateLastUsedDate("default");
            const summaryElement = document.getElementById(invoiceDateLastUsedDate);
            if (summaryElement) {
                summaryElement.checked = false; // Uncheck the checkbox if it exists
            }
        }
        
        if (dueDateLastUsedDate !== "default") {
            setDueDateLastUsedDate("default");
            const dueElement = document.getElementById("due_date_" + dueDateLastUsedDate);
            if (dueElement) {
                dueElement.checked = false; // Uncheck the checkbox if it exists
            }
        }
        setFilterFlag(false);
        setCustomerId(null);
        setStatus(null);
        setPageNo(1);
        GetTableData(1, pageSize, null, null, null, null, null, null, sortCol, sortDir, null, null,false);
        GetAllCustomers();
    }, [resellerId]);

    useEffect(() => {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('pageNo', pageNo.toString());
        newSearchParams.set('pageSize', pageSize.toString());
        newSearchParams.set('sortCol', sortCol);
        newSearchParams.set('sortDir', sortDir);
        newSearchParams.set('searchValueTable', searchValueTable);
        newSearchParams.set('serviceId', serviceId);
        newSearchParams.set('resellerId', resellerId);
        newSearchParams.set('resellerNameDefaultValue', resellerNameDefaultValue);
        newSearchParams.set('invoiceDateFrom', invoiceDateFrom);
        newSearchParams.set('invoiceDateTo', invoiceDateTo);
        newSearchParams.set('dueDateFrom', dueDateFrom);
        newSearchParams.set('dueDateTo', dueDateTo); 
        newSearchParams.set('customerId', customerId);
        newSearchParams.set('status', status); 
        newSearchParams.set('invoiceServiceLastUsedDate', invoiceServiceLastUsedDate);
        newSearchParams.set('invoiceDateLastUsedDate', invoiceDateLastUsedDate);
        newSearchParams.set('dueDateLastUsedDate', dueDateLastUsedDate);
        newSearchParams.set('customerLastUsedDate', customerLastUsedDate);
        newSearchParams.set('statusLastUsedDate', statusLastUsedDate);
        newSearchParams.set('invoiceDateCustomFromLast', invoiceDateCustomFromLast);
        newSearchParams.set('invoiceDateCustomToLast', invoiceDateCustomToLast);
        newSearchParams.set('dueDateCustomFromLast', dueDateCustomFromLast);
        newSearchParams.set('dueDateCustomToLast', dueDateCustomToLast);  
  
        window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
      }, [pageNo, pageSize,sortDir,sortCol,searchValueTable,serviceId,invoiceDateFrom,invoiceDateTo,dueDateFrom,dueDateTo,customerId,status,
        invoiceServiceLastUsedDate,invoiceDateLastUsedDate,dueDateLastUsedDate,dueDateLastUsedDate,customerLastUsedDate,statusLastUsedDate,invoiceDateCustomFromLast,invoiceDateCustomToLast,dueDateCustomFromLast,dueDateCustomToLast, resellerId]);

    useEffect(() => {
        (searchParams.get('searchValueTable') && searchParams.get('searchValueTable').length>0 && searchParams.get('searchValueTable')!=="null") ? setSearchIconFlag(true) : setSearchIconFlag(false);
        (
            (searchParams.get('serviceId') || searchParams.get('invoiceDateFrom') || searchParams.get('invoiceDateTo') || searchParams.get('dueDateFrom') || searchParams.get('dueDateTo') || searchParams.get('customerId') || searchParams.get('status')) && 
            (searchParams.get('serviceId')!=="null" || searchParams.get('invoiceDateFrom')!=="null" || searchParams.get('invoiceDateTo')!=="null" || searchParams.get('dueDateFrom')!=="null" || searchParams.get('dueDateTo')!=="null" || searchParams.get('customerId')!=="null" || searchParams.get('status')!=="null") &&
            (searchParams.get('serviceId').length>0 || searchParams.get('invoiceDateFrom').length>0 || searchParams.get('invoiceDateTo').length>0 || searchParams.get('dueDateFrom').length>0 || searchParams.get('dueDateTo').length>0 || searchParams.get('customerId').length>0 || searchParams.get('status').length>0) 
            ? setFilterFlag(true) : setShowFilterBox(false)
        );
    }, [])

    useEffect(() => {
        if(location.state?.customerId){
            // setSearchValueTable(location.state?.searchData);
            setFilterFlag(true);
        }
        
    }, [location.state?.customerId])


    return (
        <>
            <div className="main-content">
                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
                {!isDisabled && <div className="customer-management-console-main-div mb-5">
                    <div className="mgmt-console-table-row">
                        <div className="my-3 d-flex">
                            <Col className={`py-0 overflow-auto ${styles['cloud-invoice-table']}`}>
                            {isCloudAdmin && <Col md={3}>
                                <ResellerNavdropdown
                                    formGroupId="customerNameId"
                                    placeholder="Enter Reseller Code"
                                    selectDefaultValue={resellerNameDefaultValue}
                                    setSelectDefaultValue={setResellerNameDefaultValue}
                                    setOptionId={setResellerId}
                                    selectOptions={resellerData}
                                    selectOptionsFiltered={resellerNameFiltered}
                                    setSelectOptionsFiltered={setResellerNameFiltered}
                                    selectSearchValue={resellerNameSearchValue}
                                    setSelectSearchValue={setResellerNameSearchValue}
                                    updatedOptions={updatedResellerName}
                                />
                            </Col>}
                                <div className={`${styles['cloud-invoice-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header">Invoices {!emptyCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch && !emptyCloudSummaryReseller && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h5>
                                        </Col>
                                        {/* desktop and tab search and filter */}
                                        {<Col className="px-1 d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub px-2">
                                                {!serverErrorCloudInvoice && !emptyCloudInvoice && !emptyCloudSummaryReseller && 
                                                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                        {!searchIconFlag &&
                                                            <span className="mx-3">&nbsp;</span>
                                                        }
                                                        {!searchIconFlag && !emptyCloudInvoiceFilters &&
                                                            <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                        }
                                                        {searchIconFlag && !emptyCloudInvoiceFilters && !emptyCloudSummaryReseller &&
                                                            <InputGroup className="search-input-box search-input-box-large">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search by Invoice No., Summary No"
                                                                    aria-label="Search by Invoice No., Summary No"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                    ref={searchInputRef}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueTable("");
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }

                                                        {!filterFlag && !emptyCloudInvoiceSearch && !emptyCloudSummaryReseller && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {initialLoading && filterFlag && !emptyCloudSummaryReseller && !emptyCloudInvoiceSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                    </Col>}
                                            </Row>
                                        </Col>}

                                        {/* mobile search and filter */}
                                        {<Col className="px-1 d-block d-md-none" md={12}>
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub px-2">
                                                {!serverErrorCloudInvoice && !emptyCloudInvoice && !emptyCloudSummaryReseller &&
                                                    <Col className="d-flex align-items-center justify-items-center position-relative">
                                                        {!emptyCloudInvoiceFilters && !emptyCloudSummaryReseller &&
                                                            <InputGroup className="search-input-box search-input-box-xl">
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                    <span className="searchgrey cpointer"
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    placeholder="Search by Invoice No., Summary No"
                                                                    aria-label="Search by Invoice No., Summary No"
                                                                    aria-describedby="basic-addon2"
                                                                    className="search-input-box-input"
                                                                    value={searchValueTable}
                                                                    onChange={(e) => setSearchValueTable(e.target.value)}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                    onClick={() => {
                                                                        setSearchValueTable("");
                                                                        setSearchIconFlag(!searchIconFlag);
                                                                    }}>
                                                                    <span className="closebtn">
                                                                        &nbsp;
                                                                    </span>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        }

                                                        {!filterFlag && !emptyCloudInvoiceSearch && !emptyCloudSummaryReseller && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {initialLoading && filterFlag && !emptyCloudSummaryReseller && !emptyCloudInvoiceSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                    </Col>}
                                            </Row>
                                        </Col>}
                                    </div>
                                    {
                                        !emptyCloudInvoiceSearch && showFilterBox && !emptyCloudSummaryReseller && 
                                        <CloudInvoiceFilterBox serviceId={serviceId} setServiceId={setServiceId} GetTableData={GetTableData} pageNo={pageNo}
                                            pageSize={pageSize} searchValueTable={searchValueTable} invoiceDateFrom={invoiceDateFrom} invoiceDateTo={invoiceDateTo}
                                            setInvoiceDateFrom={setInvoiceDateFrom} setInvoiceDateTo={setInvoiceDateTo} dueDateFrom={dueDateFrom}
                                            setDueDateFrom={setDueDateFrom} dueDateTo={dueDateTo} setDueDateTo={setDueDateTo} sortCol={sortCol} sortDir={sortDir}
                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} invoiceServiceLastUsedDate={invoiceServiceLastUsedDate}
                                            setInvoiceServiceLastUsedDate={setInvoiceServiceLastUsedDate} invoiceDateLastUsedDate={invoiceDateLastUsedDate} status={status} setStatus={setStatus}
                                            setInvoiceDateLastUsedDate={setInvoiceDateLastUsedDate} dueDateLastUsedDate={dueDateLastUsedDate} setDueDateLastUsedDate={setDueDateLastUsedDate}
                                            invoiceDateCustomFromLast={invoiceDateCustomFromLast} setInvoiceDateCustomFromLast={setInvoiceDateCustomFromLast} invoiceDateCustomToLast={invoiceDateCustomToLast}
                                            setInvoiceDateCustomToLast={setInvoiceDateCustomToLast} dueDateCustomFromLast={dueDateCustomFromLast} setDueDateCustomFromLast={setDueDateCustomFromLast}
                                            dueDateCustomToLast={dueDateCustomToLast} setDueDateCustomToLast={setDueDateCustomToLast} customerLastUsedDate={customerLastUsedDate} setCustomerLastUsedDate={setCustomerLastUsedDate}
                                            customerId={customerId} setCustomerId={setCustomerId} customerData={customerData} setCustomerData={setCustomerData} statusLastUsedDate={statusLastUsedDate} setStatusLastUsedDate={setStatusLastUsedDate}
                                            customerIsAuthenticated={props.customerIsAuthenticated} setCustomerIsAuthenticated={props.setAuthentication}
                                            serviceData={serviceData} statusData={statusData} setResetPaginationToggle={setResetPaginationToggle} setPageNo={setPageNo} setScrollToDivTop={setScrollToDivTop}
                                        />
                                    }
                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['cloud-invoice-history-table']}`}>
                                                    {initialLoading && serverErrorCloudInvoice &&
                                                        <div className={styles["no-data-cloud-invoice"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyCloudInvoiceSearch &&
                                                        <div className={styles["no-data-cloud-invoice"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Invoices Found</h2>
                                                        </div>
                                                    }
                                                      {initialLoading && emptyCloudSummaryReseller && !emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch && !emptyCloudInvoice &&
                                                       <div className={styles["no-data-cloud-invoice"]}>
                                                       <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                       <h2 className="mt-4 mb-3">No Invoices Found</h2>
                                                   </div>
                                                    }
                                                    {initialLoading && emptyCloudInvoiceFilters && !emptyCloudInvoiceSearch &&
                                                        <div className={styles["no-data-cloud-invoice"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-invoice-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Invoices Found</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyCloudInvoice &&
                                                        <div className={styles["no-data-cloud-invoice"]}>
                                                            <img src={RaiseTicketIllustration} className="empty-cloud-invoice-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Invoices Found</h2>
                                                        </div>
                                                    }
                                                    {!serverErrorCloudInvoice && !emptyCloudInvoiceFilters && !emptyCloudInvoice && !emptyCloudInvoiceSearch &&
                                                        <LazyTable
                                                        d={columns}
                                                        table={table}
                                                        customStyles={customStyles}
                                                        loading={infinityLoading}
                                                        pageNo={pageNo}
                                                        totalRows={totalRows}
                                                        handlePerRowsChange={handlePerRowsChange}
                                                        handlePageChange={handlePageChange}
                                                        styles={styles}
                                                        handleLazyCalls={handleLazyCalls}
                                                        serverErrorUser={serverErrorCloudInvoice}
                                                        emptyUser={emptyCloudInvoice}
                                                        emptyCloudSummaryReseller={emptyCloudSummaryReseller}
                                                        emptyUserFilters={emptyCloudInvoiceFilters}
                                                        emptyUserSearch={emptyCloudInvoiceSearch}
                                                        setScrollToDivTop={setScrollToDivTop}
                                                        scrollToDivTop={scrollToDivTop}
                                                    />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>}
                {
                    isDisabled && <AccessDenied/>
                }
            </div>
        </>
    )
}

export default InvoiceDetailsListing