import React from 'react'
import { api } from '../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_ADDONS, GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS } from '../../../../../../../Utils/GlobalConstants';
import { useEffect, useState, useRef } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { Container, Row, Col, Dropdown, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./styles.scss";
import { XLg } from "react-bootstrap-icons";
import QuantityEditor from '../../../QuantityEditer';
import GWSUpgrade from '../Actions/GWSUpgrade';
import GWSEditQuantity from '../Actions/GWSEditQuantity';
import GWSChangePaymentPlan from '../Actions/GWSChangePaymentPlan';
import GWSRenewalSettings from '../Actions/GWSRenewalSettings';
import GWSChangeStatus from '../Actions/GWSChangeStatus';
import styles from '../../../GWS/gwsManagement.module.scss'
import Popup from 'reactjs-popup';
import Toast from '../../../../../../../GlobalComponents/Toast';

const ExpandableTable = ({ data, GetAdditionalDetails, customerNameDefaultValue,resellerId }) => {
    const alphanumericRegex = /^[a-zA-Z0-9]{0,25}$/; // RegEx for PO Number
    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array").includes("Manage") ? true : false);
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["", "Plan", "Assigned Seats", "Quantity", "Billing Term"
        , "Start Date", "Renewal Date", "Subscription Type", "Status",
        "Info", "Actions"];                                                         // table headers used to make object of react data table component
    let ColumnNameReadOnly = ["", "Plan", "Assigned Seats", "Quantity", "Billing Term"
        , "Start Date", "Renewal Date", "Subscription Type", "Status", "Info"];          // table headers used to make object of react data table component
    const [initialLoading, setInitialLoading] = useState(true);                     // initial loading of data check
    const [serverErrorUser, setServerErrorUser] = useState(false);                  // server error check state in table during api call to load data
    const [status, setStatus] = useState(null);                                     // getTableData() status
    const [billingTerm, setBillingTerm] = useState(null);                           // getTableData() billing term
    const [planType, setPlanType] = useState(null);                                 // getTableData() plan type
    const [showTableLoading, setShowTableLoading] = useState("Loading . . . ");     // loading status of table
    const [errorMessage, setErrorMessage] = useState(false);                        // getTableData() plan type
    const [subscriptionTableAPIData, setSubscriptionTableAPIData] = useState(null);
    const [editQuantityActionsValue, setEditQuantityActionsValue] = useState(null);
    const [newQuantity, setNewQuantity] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updatedQuanity, setUpdatedQuanity] = useState(null);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(null);

    // Edit Quantity PopUp
    const [editingQuantityRowId, setEditingQuantityRowId] = useState(null);
    const [editedQuantity, setEditedQuantity] = useState(null);
    const [isEditingQuantity, setIsEditingQuantity] = useState(false);
    const [minQuantity, setMinQuantity] = useState(null);
    const [maxQuantity, setMaxQuantity] = useState(null);
    const editQuantityRef = useRef();
    const [editQuantityModalOpen, setEditQuantityModalOpen] = useState(false);
    const [actionsSubscriptionId, setActionsSubscriptionId] = useState(null);
    const [actionsPlanName, setActionsPlanName] = useState(null);
    let ColumnNameSubscriptionTable = ["Billing Term", "Quantity", "Auto Renew", "RRP (Inc GST)", "Price (Ex GST)"];   // table headers used to make object of react data table component 
    const [columnsSubscriptionTable, setColumnsSubscriptionTable] = useState([]);                                       // columns used to display column headers in the react data table component
    const [tableSubscriptionTable, setTableSubscriptionTable] = useState([]);
    const [setUpBillingActionFlag, setSetUpBillingActionFlag] = useState(false);

    //actions popup modal
    const [editQuantityActionPopupModalOpen, setEditQuantityActionPopupModalOpen] = useState(false);
    const [upgradeActionPopupModalOpen, setUpgradeActionPopupModalOpen] = useState(false);
    const [changePaymentPlanActionPopupModalOpen, setChangePaymentPlanActionPopupModalOpen] = useState(false);
    const [renewalSettingsActionPopupModalOpen, setRenewalSettingsActionActionPopupModalOpen] = useState(false);
    const [changeStatusActionPopupModalOpen, setChangeStatusActionPopupModalOpen] = useState(false);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message  
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                          // check whether terms and condition is checked or not

    //info icon popup showing subscription details
    const [isSubscriptionDetailsPopupOpen, setIsSubscriptionDetailsPopupOpen] = useState(false);                        // Subscription Details Popup Box
    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false)                       // Subscription is Loading Popup
    const [subscriptionDetailsPlanName, setSubscriptionDetailsPlanName] = useState("Loading...");
    const [subscriptionDetailsVendorSubscriptionId, setSubscriptionDetailsVendorSubscriptionId] = useState("Loading...");
    const [subscriptionDetailsLicenceAutoRenewal, setSubscriptionDetailsLicenceAutoRenewal] = useState("Loading...");
    const [subscriptionDetailsStartDate, setSubscriptionDetailsStartDate] = useState("Loading...");
    const [subscriptionDetailsEndDate, setSubscriptionDetailsEndDate] = useState("Loading...");
    const [subscriptionDetailsRRP, setSubscriptionDetailsRRP] = useState("Loading...");
    const [subscriptionDetailsIsTrial, setSubscriptionDetailsIsTrial] = useState(null);
    const [subscriptionDetailsTrialEndDate, setSubscriptionDetailsTrialEndDate] = useState("Loading...");

    const [actionCompletedRefreshSubscriptionTable, setActionCompletedRefreshSubscriptionTable] = useState(false)
    const [poNumber, setPoNumber] = useState("");
    let detailsColumn = ["Plan", "Billing Term", "Unit Price", "Quantity", "Sub-Total Price"];
    const [detailsTable, setDetailsTable] = useState([]);
    const [detailsTableColumn, setDetailsTableColumn] = useState([]);
    const [addonsTotalLength, setAddonsTotalLength] = useState(null);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //Tootltip
    const renderTooltip = (props) => (
        <Tooltip {...props}>{props.infoText}</Tooltip>
    );

    //save edit quantity
    const handleEditQuantitySave = (row) => {
        setEditQuantityModalOpen(true);
    };

    // close edit quantity
    const handleEditQuantityClose = () => {
        setEditingQuantityRowId();
        setEditedQuantity(null);
        setIsEditingQuantity(false);
        setMinQuantity(null);
        setMaxQuantity(null);
    };

    // increase value in input box
    const handleIncrease = (qty) => {
        if (editedQuantity <= maxQuantity) {
            setEditedQuantity(parseInt(qty) + 1);
            setIsEditingQuantity(true);
        } else {
            setEditedQuantity(parseInt(qty));
        }
    }

    // decrease value in input box
    const handleDecrease = (qty) => {
        if (editedQuantity >= minQuantity) {
            setEditedQuantity(parseInt(qty) - 1);
            setIsEditingQuantity(true);
        } else {
            setEditedQuantity(parseInt(qty));
        }
    }

    // useEffect for calling addon table
    useEffect(() => {
        GetTableData(status, billingTerm, planType);
    }, []);

    // when edit icon is clicked
    const handleEditQuantityClick = (row) => {
        setEditedQuantity(row["Quantity"]);
        setEditingQuantityRowId(row.subscriptionId);
        setMinQuantity(row["Minimum Quantity"]);
        setMaxQuantity(row["Maximum Quantity"]);
    };

    // Update Edit Quantity
    async function UpdateEditQuantity() {
        setConfirmLoadingFlag(true);
        let requestBody = {
            "quantity": parseInt(editedQuantity),
            "subscriptionId": parseInt(subscriptionId),
            "poNumber": poNumber
        };

        await api.put(GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + "change-quantity", requestBody, config)
            .then(resp => {
                if (resp.status = 200) {
                    setEditQuantityModalOpen(false);
                    setConfirmLoadingFlag(false);
                    setToastMessage("Quantity Updated Successfully");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setEditingQuantityRowId();
                    setEditedQuantity(null);
                    setIsEditingQuantity(false);
                    setActionCompletedRefreshSubscriptionTable(true);
                }
            })
            .catch(error => {
                setEditQuantityModalOpen(false);
                setConfirmLoadingFlag(false);
                setToastMessage("We encountered an issue while updating the quantity. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
            })
    }

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className='threeDotAnchorTag'
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
    ));

    // Function to add data in View Subscription Details Popup
    async function GetSubscriptionDetailsPopup(subscriptionid) {
        setSubscriptionDetailIsLoadingFlag(true);
        await api
            .get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + subscriptionid}?requestedResellerCode=${resellerId}`, config)
            .then((resp) => {
                setSubscriptionDetailsPlanName(resp.data.planName);
                setSubscriptionDetailsVendorSubscriptionId(
                    resp.data.vendorSubscriptionId
                );
                setSubscriptionDetailsLicenceAutoRenewal(resp.data.autoRenew);
                setSubscriptionDetailsStartDate(resp.data.startDate);
                setSubscriptionDetailsEndDate(resp.data.subscriptionEndDate);
                setSubscriptionDetailsRRP("");
                setSubscriptionDetailsIsTrial(resp.data.isTrial);
                setSubscriptionDetailsTrialEndDate(resp.data.trailEndDate);
                setSubscriptionDetailIsLoadingFlag(false);
            })
            .catch((error) => {
                if (error?.status == 401 || error?.response?.status == 401) {
                }
                else {
                    setSubscriptionDetailIsLoadingFlag(false);
                }
            });
    }

    //for actions core data 
    async function GetActionsData(subscriptionId) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS}${subscriptionId}`, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {

                }
                // handle success
                else {
                    let f = [];
                    setSubscriptionTableAPIData(resp.data)
                    setMaxQuantity(resp.data.maxQuantity);
                    setMinQuantity(resp.data.minQuantity);
                    setNewQuantity(resp.data.quantity);
                    setEditQuantityActionsValue(resp.data.quantity);
                    f.push
                        (
                            {
                                "Billing Term": resp.data.billingTerm,
                                "Assigned Seats": resp.data.assignedSeat,
                                "Quantity": resp.data.quantity,
                                "Status": resp.data.status === 'ACTIVE' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-active"></span>
                                        <div className="py-1 text-muted text-small">Active</div>
                                    </div>
                                ) : resp.data.status === 'CANCELLED' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-failed"></span>
                                        <div className="py-1 text-muted text-small">Cancelled</div>
                                    </div>
                                ) : resp.data.status === 'PENDING' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-pending"></span>
                                        <div className="py-1 text-muted text-small">Pending</div>
                                    </div>
                                ) : (
                                    resp.data.status
                                ),
                                "Auto Renew": (resp.data.autoRenew ? "Yes" : "No"),
                                "Subscription Type": resp.data.subscriptionType,
                                "RRP (Inc GST)": `$${resp.data.rrp}`,
                                "Price (Ex GST)": `$${resp.data.price}`,
                                "Start Date": resp.data.startDate,
                                "Renewal Date": resp.data.renewalDate,
                            }
                        );
                    setTimeout(() => {
                        setTableSubscriptionTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnNameSubscriptionTable.map((td) => {
                        if (td === "RRP (Inc GST)" || td === "Price (Ex GST)" || td === "Quantity") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else if (td === "Billing Term") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                    >
                                        <span className="infoIcon-support-table ml-1"></span>
                                    </OverlayTrigger></div>,
                                selector: row => <div className="text-wrap">
                                    {row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumnsSubscriptionTable(d);     // Setting Column Data
                }

            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                }
                else {
                }

            });
    };

    // Get Table Data
    async function GetTableData(status, billingTerm, planType) {
        setInitialLoading(false);
        (status === undefined || status === null) ? status = "" : status = status;
        (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
        (planType === undefined || planType === null) ? planType = "" : planType = planType;
        await api.get(`${GOOGLE_WORKSPACE_ADDONS}?parentSubscriptionId=${data.subscriptionId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setInitialLoading(true);
                setErrorMessage(false);
                if (resp.status == 204) {
                    {
                        setShowTableLoading("No AddOns Found !");
                    }
                }
                // handle success
                else {
                    setShowTableLoading("Loading  . . . ");
                    setAddonsTotalLength(resp.data);
                    setServerErrorUser(false);
                    let f = [];
                    (resp.data).map((td) => {
                        f.push
                            (
                                {
                                    "Plan": td.planName,
                                    "has Add On": td.hasAddOn,
                                    "Assigned Seats": td.assignedSeat,
                                    "subscriptionId": td.subscriptionId,
                                    "Quantity": td.quantity,
                                    "Billing Term": td.billingTerm,
                                    "Start Date": td.startDate,
                                    "Renewal Date": td.renewalDate,
                                    "Subscription Type": td.planType,
                                    "Status": !(td.isSuspendReasonFlag) ? ((td.status) === 'ACTIVE' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : td.status === 'CANCELLED' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-failed"></span>
                                            <div className="py-1 text-muted text-small">Cancelled</div>
                                        </div>
                                    ) : td.status === 'PENDING' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-muted text-small">Pending</div>
                                        </div>
                                    ) : td.status === 'SUSPENDED' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-suspended"></span>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : (
                                        td.status
                                    )) : ((td.status) === 'ACTIVE' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-active"></span>
                                            <div className="py-1 text-muted text-small">Active</div>
                                        </div>
                                    ) : td.status === 'CANCELLED' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-failed"></span>
                                            <div className="py-1 text-muted text-small">Cancelled</div>
                                        </div>
                                    ) : td.status === 'PENDING' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-pending"></span>
                                            <div className="py-1 text-muted text-small">Pending</div>
                                        </div>
                                    ) : td.status === 'SUSPENDED' ? (
                                        <div className={`${styles['status-gws']}`}>
                                            <span className="status-suspended"></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip({
                                                    infoText: "Subscription is suspended until customer accepts Google’s new Terms of Service. Please ask the customer to login into Google Admin portal and accept Terms of Service.",
                                                })}
                                            >
                                                <span className="infoIcon-suspended ml-1 mt-1"></span>
                                            </OverlayTrigger>
                                            <div className="py-1 text-muted text-small">Suspended</div>
                                        </div>
                                    ) : (
                                        td.status
                                    )),
                                    "Info": <span
                                        className="infoIcon"
                                        onClick={() => {
                                            setIsSubscriptionDetailsPopupOpen(true);
                                            GetSubscriptionDetailsPopup(td.subscriptionId);
                                        }}
                                    >
                                        &nbsp;
                                    </span>,
                                    "Manage": <span className='manage-account-azure'>&nbsp;</span>,
                                    "Actions": <>
                                        {(td.status !== "PENDING" && td.status !== "CANCELLED" && !(td.isSuspendReasonFlag) && td.actions.length>0) ? <Dropdown drop={"start"}>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm">
                                                {td.actions.map((items) => {
                                                    let icon;
                                                    if (items.id === 'editquantity') {
                                                        icon = <span className='Edit-Icon'></span>;
                                                    } else if (items.id === 'changestatus') {
                                                        icon = <span className='change-status'></span>;
                                                    } else if (items.id === 'upgrade') {
                                                        icon = <span className='upgrade'></span>;
                                                    } else if (items.id === 'changepaymentplan') {
                                                        icon = <span className='change-payment-plan'></span>;
                                                    } else {
                                                        icon = <span className='renewal-settings'></span>;
                                                    }
                                                    return (
                                                        <Dropdown.Item key={items.id} onClick={() => { GetActionsData(td.subscriptionId); handleActionsClick(items.id); setActionsSubscriptionId(td.subscriptionId); setActionsPlanName(td.planName) }}>
                                                            {icon} {items.value}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown> : <span className={`${styles['threedots']} three-dots-icon-disabled`} />}
                                    </>
                                    ,
                                    "Minimum Quantity": td.minQuantity,
                                    "Maximum Quantity": td.maxQuantity
                                }
                            );
                    })
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {

                }
                else {
                    setServerErrorUser(true);
                    setShowTableLoading("Facing Issue in reaching Server.Please Try Again Later !");
                    setErrorMessage(true);
                }

            });

        const handleActionsClick = (id) => {
            switch (id) {
                case 'editquantity':
                    setEditQuantityActionPopupModalOpen(true);
                    break;
                case 'changestatus':
                    setChangeStatusActionPopupModalOpen(true);
                    break;
                case 'upgrade':
                    setUpgradeActionPopupModalOpen(true);
                    break;
                case 'changepaymentplan':
                    setChangePaymentPlanActionPopupModalOpen(true);
                    setSetUpBillingActionFlag(false);
                    break;
                case 'renewal-settings':
                    setRenewalSettingsActionActionPopupModalOpen(true);
                    break;
                default:
            }
        };

        // React Data Table Custom Theme
        createTheme('solarized', {
            text: {
                primary: '#000000',
            },
            background: {
                default: '#FFFFFF',
            },
            divider: {
                default: '#d7dadf',
            },
            striped: {
                default: '#f5f5f5',
            },
        });
    };

    let d = [];
    (isreadOnly ? ColumnNameReadOnly : ColumnName).map((td) => {
        if (td === "Actions") {
            d.push({
                id: `${td}`,
                name: <div className='d-none-mobile'>{td}</div>,
                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Status" || td === "Info") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Plan") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.8,
                allowOverflow: true,
            })
        }
        else if (td === "Subscription Type") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2,
                allowOverflow: true,
            })
        }
        else if (td === "Quantity") {

            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                cell: (row) => {
                    let isActive = (row.Status && row.Status.props && row.Status.props.children[1].props.children) === 'Active';
                    return isActive ? (
                        editingQuantityRowId === row.subscriptionId ? (
                            <div className='d-flex flex-wrap justify-content-between my-2'>
                                <div className="quantityEditor">
                                    <QuantityEditor
                                        editedQuantity={editedQuantity}
                                        editQuantityRef={editQuantityRef}
                                        setEditedQuantity={setEditedQuantity}
                                        handleIncrease={handleIncrease}
                                        handleDecrease={handleDecrease}
                                        setIsEditingQuantity={setIsEditingQuantity}
                                        maxQuantity={maxQuantity}
                                        minQuantity={minQuantity}
                                        updatedQuanity={row["Quantity"]}
                                    />

                                    {isEditingQuantity ? (
                                        <div className='mx-1' onClick={() => handleEditQuantitySave(row)}>
                                            <span className="quantity-tick" />
                                        </div>
                                    ) : (
                                        <div className='mx-1'>
                                            <span className="tick-grey" />
                                        </div>
                                    )}
                                    <div className='mx-1' onClick={handleEditQuantityClose}>
                                        <span className="quantity-cross">&nbsp;</span>
                                    </div>
                                </div>
                                <div className='text-disabled col-12'>
                                    <span className="text-small">Min {minQuantity}, </span>
                                    <span className="text-small">Max {maxQuantity}</span>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {row["Quantity"]} <span onClick={() => handleEditQuantityClick(row)} className={isreadOnly ? 'd-none' : "quantity-active px-4"}></span>
                            </div>
                        )) : (
                        <div>
                            {row["Quantity"]}
                            <span className={isreadOnly ? 'd-none' : "quantity-disable px-4 d-none-mobile"}></span>
                        </div>
                    )
                },
                left: true,
                allowOverflow: true,
                grow: 2.8
            })
        }
        else if (td === "Billing Term") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">
                    {row[`${td}`]}</div>,
                left: true,
                grow: 3,
                allowOverflow: true,
            })
        }
        else if (td === "Start Date") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2,
            })
        }
        else if (td === "Renewal Date") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.5,
            })
        }
        else {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
            })
        }

    })

    // React Data Table Small Custom Styles
    const customStyles = {
        table: {
            style: {
                overflow: 'visible'
            },
        },

        tableWrapper: {
            style: {
                minHeight: addonsTotalLength?.length < 3 ? '4rem' : '110px',
                overflow: 'visible'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    //subscription detail api when green tick is clicked
    async function GetSubscriptionDetails() {
        setLoading(true);
        setSubscriptionId(editingQuantityRowId);
        await api.get(GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + editingQuantityRowId, config)
            .then(resp => {
                setLoading(false);
                setMaxQuantity(resp.data.maxQuantity);
                setMinQuantity(resp.data.minQuantity);
                let f = [];
                f.push
                    (
                        {
                            "Plan": resp.data.planName,
                            "Billing Term": resp.data.billingTerm,
                            "Unit Price": `$${resp.data.price}`,
                            "Quantity": editedQuantity,
                            "Sub-Total Price": "$" + Math.round((((resp.data.price) * parseInt(editedQuantity)) + Number.EPSILON) * 100) / 100
                        }
                    );
                setTimeout(() => {
                    setDetailsTable(f);         // Setting Table Data
                }, 50);
                let d = [];
                detailsColumn.map((td) => {
                    if (td === "Unit Price" || td === "Quantity" || td === "Sub-Total Price") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            right: true,
                            grow: 1
                        })
                    }
                    else if (td === "Billing Term") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                >
                                    <span className="infoIcon-support-table ml-1"></span>
                                </OverlayTrigger></div>,
                            selector: row => <div className="text-wrap">
                                {row[`${td}`]}</div>,
                            left: true,
                            grow: 2,
                            allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            left: true,
                            grow: (td === "Billing Term") ? 3 : 1.5,
                        })
                    }
                })
                setDetailsTableColumn(d);     // Setting Column Data
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                }
                else {
                }

            });
    };
    // Refreshing Subscription Table on Action Completion
    useEffect(() => {
        if (actionCompletedRefreshSubscriptionTable) {
            GetAdditionalDetails();
            GetTableData(status, billingTerm, planType)
            setActionCompletedRefreshSubscriptionTable(false);
        }
    }, [actionCompletedRefreshSubscriptionTable])

    useEffect(() => {
        if (editQuantityModalOpen) {
            GetSubscriptionDetails();
        }
    }, [editQuantityModalOpen]);

    return (
        <div>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            {/* Subscription Details Popup */}
            <Popup
                className="custom-modal"
                open={isSubscriptionDetailsPopupOpen}
                onClose={() => setIsSubscriptionDetailsPopupOpen(false)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        {!subscriptionDetailIsLoadingFlag &&
                            <>
                                <div className="header">
                                    <h2>Subscription Details</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container>
                                    <Row>
                                        <Col className="mb-3">
                                            <label className="text-medium">
                                                Plan
                                            </label>
                                            <p>
                                                <strong>
                                                    {subscriptionDetailsPlanName}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3">
                                            <label className="text-medium">
                                                Vendor Subscription Id
                                            </label>
                                            <p className="mb-0 text-medium">
                                                <strong>
                                                    {subscriptionDetailsVendorSubscriptionId.toUpperCase()}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col className="mb-3">
                                            <label className="text-medium">
                                                Licence Auto Renewal
                                            </label>
                                            <p className="text-medium">
                                                <strong>
                                                    {subscriptionDetailsLicenceAutoRenewal ? "Yes" : "No"}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mb-0">
                                        <Col md={6}>
                                            <label className="text-medium">
                                                Subscription Start Date
                                            </label>
                                            <p className="text-medium">
                                                <strong>
                                                    {subscriptionDetailsStartDate}
                                                </strong>
                                            </p>
                                        </Col>
                                        <Col md={6}>
                                            <label className="text-medium">
                                                Subscription End Date
                                            </label>
                                            <p className="text-medium">
                                                <strong>
                                                    {subscriptionDetailsEndDate}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {subscriptionDetailsIsTrial && (
                                            <Col lg={6} md={6} className="mb-3">
                                                <label className="text-medium">
                                                    Is Trial
                                                </label>
                                                <p className="text-medium mb-0">
                                                    <strong>
                                                        {subscriptionDetailsIsTrial ? "Yes" : "No"}
                                                    </strong>
                                                </p>
                                            </Col>
                                        )}
                                        {subscriptionDetailsIsTrial && (
                                            <Col lg={6} md={6} className="mb-3">
                                                <label className="text-medium mb-0">
                                                    Trial End Date
                                                </label>
                                                <p className="text-medium">
                                                    <strong>
                                                        {subscriptionDetailsTrialEndDate}
                                                    </strong>
                                                </p>
                                            </Col>
                                        )}

                                    </Row>
                                </Container>
                            </>
                        }
                        {
                            subscriptionDetailIsLoadingFlag &&
                            <>
                                <div className="header">
                                    <h2 className="mb-0">Subscription Details</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong"
                                        onClick={close}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container>
                                    <div className="empty-subscription-detail">
                                        <center><h2> Loading . . .</h2></center>
                                    </div>
                                </Container>
                            </>
                        }
                    </div>
                )}
            </Popup>
            {/* Edit Quantity Modal */}
            <Popup
                open={editQuantityModalOpen}
                onClose={() => { setEditQuantityModalOpen(false); }}
                className="custom-modal custom-modal-xl custom-modal-ticket-details"
                closeOnDocumentClick={!confirmLoadingFlag}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            <h2>Order Confirmation - Edit Quantity</h2>
                            <XLg
                                size={24}
                                className="cpointer close-icon-popup"
                                onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                            />
                        </div>
                        <Container fluid>
                            <Row className="pb-3 pt-5">
                                <Col lg={8}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Customer Name: <span className="text-regular">{customerNameDefaultValue}</span></h3>
                                </Col>
                                <Col lg={4}>
                                    <h3 className="mb-3 text-regular text-bold-strong">Cloud Service: <span className="text-regular">Google Workspace</span></h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {detailsTable &&
                                        <DataTable
                                            columns={detailsTableColumn}
                                            data={detailsTable}
                                            theme="solarized"
                                            customStyles={customStyles}
                                            persistTableHead={false}
                                            progressComponent={<div className={styles["loading-subscriptions"]}><p><b>Loading Details . . .</b></p></div>}
                                            width="100%"
                                            fixedHeaderScrollHeight="60vh"
                                            progressPending={loading}
                                            fixedHeader
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className="py-3">
                                <Col>

                                    <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="w-auto d-inline ml-3"
                                        id="inputPONumber"
                                        placeholder='Add PO Number'
                                        aria-describedby="inputPONumber"
                                        maxlength="25"
                                        value={poNumber}
                                        onChange={(e) => {
                                            alphanumericRegex.test(e.target.value)
                                                ? setPoNumber(e.target.value)
                                                : console.log("")
                                        }}
                                    />
                                    <Form.Text id="poNumber" />

                                </Col>
                            </Row>
                            <Row className="mt-2 mb-4">
                                <Col md={6}>

                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="termsCheckBox"
                                        label=""
                                        disabled
                                        checked={isTermsChecked}
                                        className="d-inline-block w-auto"
                                    />
                                    <Form.Label className="d-inline-block  w-auto mb-0">
                                        I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                    </Form.Label>

                                </Col>
                                <Col md={6}>
                                    <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                        {!confirmLoadingFlag && isTermsChecked && <>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={() => { setEditQuantityModalOpen(false); }}>Cancel</Button>
                                            <Button
                                                className="px-4 mx-2"
                                                onClick={UpdateEditQuantity}>
                                                Confirm
                                            </Button>
                                        </>
                                        }
                                        {!confirmLoadingFlag && !isTermsChecked &&
                                            <>
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { setEditQuantityModalOpen(false); }}>Cancel
                                                </Button>
                                                <Button
                                                    variant="light"
                                                    className="px-4 mx-2"
                                                    disabled
                                                >
                                                    Confirm
                                                </Button>
                                            </>
                                        }
                                        {confirmLoadingFlag &&
                                            <>
                                                <Button
                                                    variant="light"
                                                    disabled
                                                    className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                >Cancel
                                                </Button>
                                                <Button
                                                    disabled
                                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                    variant="light"
                                                >
                                                    Processing . . .
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                )}
            </Popup>

            {
                upgradeActionPopupModalOpen &&
                <GWSUpgrade subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId}
                    upgradeActionPopupModalOpen={upgradeActionPopupModalOpen} setUpgradeActionPopupModalOpen={setUpgradeActionPopupModalOpen}
                    columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                    GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                />
            }
            {editQuantityActionPopupModalOpen &&
                <GWSEditQuantity editQuantityActionPopupModalOpen={editQuantityActionPopupModalOpen} setEditQuantityActionPopupModalOpen={setEditQuantityActionPopupModalOpen} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                    subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} columnsSubscriptionTable={columnsSubscriptionTable} setColumnsSubscriptionTable={setColumnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                    setTableSubscriptionTable={setTableSubscriptionTable} minQuantity={minQuantity} maxQuantity={maxQuantity} editQuantityActionsValue={editQuantityActionsValue} setEditQuantityActionsValue={setEditQuantityActionsValue} newQuantity={newQuantity} setNewQuantity={setNewQuantity} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                />
            }
            {
                changePaymentPlanActionPopupModalOpen &&
                <GWSChangePaymentPlan subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                    changePaymentPlanActionPopupModalOpen={changePaymentPlanActionPopupModalOpen} setChangePaymentPlanActionPopupModalOpen={setChangePaymentPlanActionPopupModalOpen}
                    columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                    GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setUpBillingActionFlag={setUpBillingActionFlag}
                    setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                />
            }
            {
                renewalSettingsActionPopupModalOpen &&
                <GWSRenewalSettings subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                    renewalSettingsActionPopupModalOpen={renewalSettingsActionPopupModalOpen} setRenewalSettingsActionActionPopupModalOpen={setRenewalSettingsActionActionPopupModalOpen}
                    columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable}
                    GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                    setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                />
            }
            {
                changeStatusActionPopupModalOpen &&
                <GWSChangeStatus subscriptionTableAPIData={subscriptionTableAPIData} setSubscriptionTableAPIData={setSubscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                    changeStatusActionPopupModalOpen={changeStatusActionPopupModalOpen} setChangeStatusActionPopupModalOpen={setChangeStatusActionPopupModalOpen}
                    columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                    GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                />
            }

            <Container fluid className="p-0">
                <div>
                    <Col>
                    <div className="table-responsive csp-expandable-table">
                            {errorMessage && <p className='text-center py-3'>{showTableLoading}</p>}
                            <div className='expandable-table services-expandable-table'>
                                {!serverErrorUser && !errorMessage &&
                                    <DataTable
                                        columns={d}
                                        data={table}
                                        customStyles={customStyles}
                                        persistTableHead={false}
                                        theme="solarized"
                                        noTableHead
                                        noDataComponent={<div className="loading-Add-Ons"><p>{showTableLoading}</p></div>}
                                        width="100%"
                                    />}
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </div>
    )
}

export default ExpandableTable