import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip, useAccordionButton } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './GWSDowngrade.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_DOWNGRADE_CORE, GOOGLE_WORKSPACE_DOWNGRADE_PLAN_OFFER, GOOGLE_WORKSPACE_CORE_PURCHASABLE_OFFERS_DOWNGRADE, GOOGLE_WORKSPACE_CORE_PURCHASABLE_OFFERS } from '../../../../../../../../Utils/GlobalConstants';
import CustomNumberSimpleInput from '../../../../../../../../GlobalComponents/CustomNumberSimpleInput';
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"


const GWSDowngrade = ({ subscriptionTableAPIData, subscriptionId, downgradeActionPopupModalOpen, setDowngradeActionPopupModalOpen, setActionCompletedRefreshSubscriptionTable,
    columnsSubscriptionTable, tableSubscriptionTable, setToastSuccess, setToastError, setToastMessage, setSubscriptionTableAPIData, Logout }) => {

    const alphanumericRegex = /^[a-zA-Z0-9]{0,25}$/; // RegEx for PO Number

    const [loading, setLoading] = useState(false);                    //loading state when api is called
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);       //order confirmation checkbox
    const [defaultDowngrade, setDefaultDowngrade] = useState();           //stepper step1 which contain selected data
    const [defaultDowngradeStep2, setDefaultDowngradeStep2] = useState(); //stepper step2 which contain selected data
    const [confirmDowngradeLoadingFlag, setConfirmDowngradeLoadingFlag] = useState(false); //onclose popup functionality and processing button after confirmation


    //for downgrade core
    let DowngradeCoreColumnName = ["", "Plan", "Description"];          //first downgrade table columns
    let DowngradeCoreColumnNameStep2 = ["", "Plan", "Billing Term", "RRP (Inc GST)", "Reseller Price (Ex GST)"];  //second step columns in downgrade
    const [coreDowngradeDetails, setCoreDowngradeDetails] = useState();             // data containing all details of step 1 in stepper 
    const [coreDowngradeDetailsStep2, setCoreDowngradeDetailsStep2] = useState();   //data containing all details of step 2 in stepper 
    const [downgradeCoreTableStep3, setDowngradeCoreTableStep3] = useState([]);     // step 3 edit quantity table 
    const [downgradeCoreColumnsStep3, setDowngradeCoreColumnsStep3] = useState([]); // step 3 edit quantity columns
    let DowngradeCoreColumnNameStep3 = ["Plan", "Billing Term", "RRP (Inc GST)", "Reseller Price (Ex GST)", "Quantity", "Sub-Total Price"]; // step3 columns -edit quantity
    const [downgradeCoreTableStep4, setDowngradeCoreTableStep4] = useState([]);   //order confirmation table
    const [downgradeCoreColumnsStep4, setDowngradeCoreColumnsStep4] = useState([]); //order confirmation column
    let DowngradeCoreColumnNameStep4 = ["Plan", "Billing Term", "Unit Price","Renewal Price (Ex GST)*", "Quantity", "Sub-Total Price"];
    const [coreDowngradeNewQuantity, setCoreDowngradeNewQuantity] = useState(null); //edit quantity 
    const [poNumber, setPoNumber] = useState("");   // post api order confirmation po number 
    const [allSelectedDetailsStep2, setAllSelectedDetailsStep2] = useState([]);  // data stored in step 2
    const [emptyDataError, setEmptyDataError] = useState(false);                                                                  // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                                                        // to check server error-400

    //Stepper
    const steps = ['Select Plan', 'Select SKU', 'Add Quantity']; //all the steps in stepper
    const [activeStep, setActiveStep] = useState(0);  // active step in stepper
    const [skuName, setSkuName] = useState();  //storing sku name so that it can be passed to next api

    //buttons for downgrade stepper
    const [buttonCheckstep1, setButtonCheckstep1] = useState(false);
    const [buttonCheckstep2, setButtonCheckstep2] = useState(false);
    const [buttonCheckstep3, setButtonCheckstep3] = useState(false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //downgrade core step 1
    async function GetDowngradeDetails(subscriptionId) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${GOOGLE_WORKSPACE_DOWNGRADE_CORE}?subscriptionId=${subscriptionId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setCoreDowngradeDetails(resp.data.purchasableSkuDetails);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };
    //downgrade core step 2
    async function GetDowngradePurchasableOffersCore(subscriptionId, skuName) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        (skuName === undefined || skuName === null) ? skuName = "" : skuName = skuName;

        await api.get(`${GOOGLE_WORKSPACE_CORE_PURCHASABLE_OFFERS_DOWNGRADE}?subscriptionId=${subscriptionId}&skuName=${skuName}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setCoreDowngradeDetailsStep2(resp.data.purchasableOfferDetails);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };
    //downgrade core step 3
    function CoreDowngradeStep3() {
        if (subscriptionTableAPIData && skuName && allSelectedDetailsStep2) {
            // Column Edited Quantity
            let f = [];
            f.push
                (
                    {
                        "RRP (Inc GST)": `$${allSelectedDetailsStep2.rrpInclusiveTax}`,
                        "Reseller Price (Ex GST)": `$${allSelectedDetailsStep2.price}`,
                        "Billing Term": allSelectedDetailsStep2.periodType,
                        "Commitment Term": allSelectedDetailsStep2.paymentPlan,
                        "Plan": allSelectedDetailsStep2.planName,
                        "Billing Term": allSelectedDetailsStep2.billingCommitmentTerm,
                        "Current Quantity": subscriptionTableAPIData.quantity,
                        "Quantity":
                            <div className='d-flex flex-wrap my-2'>
                                <CustomNumberSimpleInput
                                    defaultValue={coreDowngradeNewQuantity ? coreDowngradeNewQuantity : subscriptionTableAPIData.quantity}
                                    min={allSelectedDetailsStep2.minValue}
                                    max={allSelectedDetailsStep2.maxValue}
                                    setUpdatedQuanity={setCoreDowngradeNewQuantity}
                                />
                            </div>
                        ,
                        "Sub-Total Price": `$${coreDowngradeNewQuantity ? ((allSelectedDetailsStep2.price) * coreDowngradeNewQuantity).toFixed(2)
                            : ((allSelectedDetailsStep2.price)) * subscriptionTableAPIData.quantity}`
                    }
                );
            setDowngradeCoreTableStep3(f);         // Setting Table Data      
            let d = []
            DowngradeCoreColumnNameStep3.map((td) => {
                if (td === "Sub-Total Price") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        allowOverflow: true,
                    })
                }
                else if (td === "Billing Term") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                                <span className="infoIcon-support-table ml-1"></span>
                            </OverlayTrigger></div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }
                else if (td === "RRP (Inc GST)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 1.5,
                        allowOverflow: true,
                    })
                }
                else if (td === "Reseller Price (Ex GST)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        allowOverflow: true,
                    })
                }
            })
            setDowngradeCoreColumnsStep3(d);
        }
    }
    //order confirmation table last step for downgrade core 
    function CoreDowngradeOrderConfirmation() {
        if (subscriptionTableAPIData) {
            // Column downgrade tab level step 3
            let f = [];
            f.push
                (
                    {
                        "Unit Price": `$${allSelectedDetailsStep2.price}`,
                        "Renewal Price (Ex GST)*":`$${allSelectedDetailsStep2.rrpExclusiveTax}`,
                        "Plan": allSelectedDetailsStep2.planName,
                        "Quantity": coreDowngradeNewQuantity,
                        "Billing Term": allSelectedDetailsStep2.billingCommitmentTerm,
                        "Sub-Total Price": `$${coreDowngradeNewQuantity ? ((allSelectedDetailsStep2.price) * coreDowngradeNewQuantity).toFixed(2)
                            : ((allSelectedDetailsStep2.price)) * subscriptionTableAPIData.quantity}`

                    }
                );
            setTimeout(() => {
                setDowngradeCoreTableStep4(f);         // Setting Table Data
            }, 50);
            let d = []
            DowngradeCoreColumnNameStep4.map((td) => {
                if (td === "Unit Price" || td === "Sub-Total Price" || td === "Quantity" || td==="Renewal Price (Ex GST)*") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        allowOverflow: true,
                    })
                }
                else if (td === "Billing Term") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                            >
                                <span className="infoIcon-support-table ml-1"></span>
                            </OverlayTrigger></div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        allowOverflow: true,
                    })
                }
            })
            setDowngradeCoreColumnsStep4(d);     // Setting Column Data  
        }
    }
    //post api for downgrade confirmation
    async function CoreOrderDowngradeConfirmation() {
        setConfirmDowngradeLoadingFlag(true);
        const data = {
            "offerName": allSelectedDetailsStep2.billingFrequency,
            "offerSku": allSelectedDetailsStep2.offerSku,
            "paymentPlan": allSelectedDetailsStep2.paymentPlan,
            "planName": allSelectedDetailsStep2.planName,
            "poNumber": poNumber,
            "quantity": parseInt(coreDowngradeNewQuantity),
            "skuName": skuName,
            "subscriptionId": parseInt(subscriptionId)
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(GOOGLE_WORKSPACE_DOWNGRADE_PLAN_OFFER, data, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmDowngradeLoadingFlag(false);
                    setServerError(false);
                    setEmptyDataError(false);
                    setDowngradeActionPopupModalOpen(false);
                    setToastMessage(resp.data.statusMessage);
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                }
            })
            .catch(error => {
                setConfirmDowngradeLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setToastMessage("We encountered an issue while downgrading the subscription. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
            })
    };

    // Stepper
    const handleBackOrderConfirmation = () => {
        setButtonCheckstep3(false);
        setPoNumber("");

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleBack = () => {
        setDefaultDowngradeStep2();
        setButtonCheckstep2(false);
        setCoreDowngradeDetailsStep2();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleBackQuantity = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCoreDowngradeNewQuantity(subscriptionTableAPIData.quantity);
    };
    const handleNextStep1 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (skuName) {
            GetDowngradePurchasableOffersCore(subscriptionId, skuName)
        }
    };
    const handleNextStep2 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        CoreDowngradeStep3();
    };
    const handleNextStep3 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonCheckstep3(true);
        CoreDowngradeOrderConfirmation();
    };

    // clear the selected data
    const handleClearRows = () => {
        setDefaultDowngrade();
        setButtonCheckstep1(false);
        setButtonCheckstep2(false);
    }

    // accordion
    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='up-accordion' />
                ) : (
                    <span className='down-accordion' />
                )}
            </span>
        );
    }
    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: '0.5px solid #DDDDDD',
                wordBreak: 'normal',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                wordBreak: 'normal',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // React Data Table Custom Styles for downgrade
    const customStylesDowngrade = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                wordBreak: 'normal',
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                wordBreak: 'normal',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    useEffect(() => {
        GetDowngradeDetails(subscriptionId);
    }, [subscriptionId])

    useEffect(() => {
        CoreDowngradeStep3();
    }, [coreDowngradeNewQuantity])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xl"
                open={downgradeActionPopupModalOpen}
                onClose={() => { setDowngradeActionPopupModalOpen(false); setServerError(false); setEmptyDataError(false); setSubscriptionTableAPIData(null); }}
                closeOnDocumentClick={!(confirmDowngradeLoadingFlag || buttonCheckstep2 || buttonCheckstep1 || buttonCheckstep3)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">

                        <div className="header">
                            {buttonCheckstep3 ? <h2>Order Confirmation - Downgrade</h2> : <h2>Downgrade</h2>}
                            <XLg
                                size={18}
                                className="cpointer text-strong"
                                onClick={() => { if (!confirmDowngradeLoadingFlag) close() }}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className={`downgrade-modal-height ${styles['downgrade-modal-width']}`}>
                            <Accordion defaultActiveKey="1">
                                <Card className={`${styles['accordion-downgradeGWS']}`}>
                                    <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                        <Col md={7} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                        </Col>
                                        <Col md={4} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                        </Col>
                                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                                    </Row>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                                                {tableSubscriptionTable &&
                                                    <DataTable
                                                        columns={columnsSubscriptionTable}
                                                        data={tableSubscriptionTable}
                                                        theme="solarized"
                                                        customStyles={customStyles}
                                                        persistTableHead={false}
                                                        noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        width="100%"
                                                        fixedHeaderScrollHeight="60vh"
                                                        progressPending={loading}
                                                        progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        fixedHeader
                                                    />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            <Box sx={{ width: '100%' }} className='pt-6'>
                                <Row >
                                    <Col md={2}></Col>
                                    <Col md={8}
                                        className={`${styles['button-color-manage-gws']}`}>
                                        {activeStep !== steps.length && (
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};
                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        )}
                                    </Col>
                                    <Col md={2}></Col>
                                </Row>
                                {buttonCheckstep3 && <div className={`${styles['gws-downgrade-core-step4']}`}>
                                    {downgradeCoreTableStep4 &&
                                        <DataTable
                                            columns={downgradeCoreColumnsStep4}
                                            data={downgradeCoreTableStep4}
                                            theme="solarized"
                                            customStyles={customStyles}
                                            persistTableHead={false}
                                            noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                            width="100%"
                                            fixedHeaderScrollHeight="31vh"
                                            progressPending={loading}
                                            progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                            fixedHeader
                                        />
                                    }
                                    <p className='mt-4'><span className='text-strong'>Note* :</span> Renewal price is based on current pricing. This will change if new prices are published before renewal by google.</p>

                                    <Row>
                                        <Col>
                                            <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="w-auto d-inline ml-3"
                                                id="inputPONumber"
                                                aria-describedby="inputPONumber"
                                                placeholder='Add PO Number'
                                                value={poNumber}
                                                maxlength="25"
                                                onChange={(e) => {
                                                    alphanumericRegex.test(e.target.value)
                                                        ? setPoNumber(e.target.value)
                                                        : console.log("")
                                                }}
                                            />
                                            <Form.Text id="poNumber" />

                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6}>
                                            <Form.Check // prettier-ignore
                                                type="checkbox"
                                                id="termsCheckBox"
                                                label=""
                                                disabled
                                                checked={isTermsChecked}
                                                className="d-inline-block w-auto"
                                            />
                                            <p className="d-inline-block  w-auto mb-0">
                                                I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                            </p>

                                        </Col>
                                        <Col md={6}>
                                            <div className="btn-wrapper text-right d-md-block d-none">
                                                {isTermsChecked && <>
                                                    {!confirmDowngradeLoadingFlag && <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBackOrderConfirmation}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>}
                                                    {confirmDowngradeLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        variant="light"
                                                        disabled
                                                    >Back</Button>}
                                                    {!confirmDowngradeLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => {
                                                            CoreOrderDowngradeConfirmation();
                                                        }}
                                                    >
                                                        Confirm
                                                    </Button>}
                                                    {confirmDowngradeLoadingFlag &&
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>}
                                                </>
                                                }
                                                {!isTermsChecked &&
                                                    <>
                                                        <Button
                                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBackOrderConfirmation}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Back
                                                        </Button>

                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>}
                                <React.Fragment>
                                    {serverError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                        </div>
                                    }
                                    {emptyDataError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">No Data Found</h2>
                                        </div>
                                    }
                                    {activeStep === 0 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError &&
                                            <div className={`table-responsive pt-3 ${styles['gws-downgrade-core']}`}>
                                                {!coreDowngradeDetails &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                                {coreDowngradeDetails &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {DowngradeCoreColumnName.map(th => {
                                                                    return (<th className={th === "Plan" ? `${styles['gws-downgrade-plan-min-width']}` : ""}>{th}</th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                coreDowngradeDetails.map((td) => {
                                                                    return (<tr>
                                                                        <td>
                                                                            <input type='radio'
                                                                                id={td.skuName}
                                                                                name="downgradeOptions"
                                                                                checked={defaultDowngrade === td.skuName}
                                                                                onChange={() => {
                                                                                    setDefaultDowngrade(td.skuName);
                                                                                    setSkuName(td.skuName);
                                                                                    setButtonCheckstep1(true);
                                                                                }} />
                                                                        </td>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.planDescription}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}
                                    </Typography>}
                                    {activeStep === 1 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        <div className={`table-responsive pt-3 ${styles['gws-downgrade-core-step2']}`}>
                                            {!coreDowngradeDetailsStep2 && !serverError &&
                                                <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                            {coreDowngradeDetailsStep2?.length > 0  &&
                                                <Table responsive="md">
                                                    <thead className="sticky-top">
                                                        <tr>
                                                            {DowngradeCoreColumnNameStep2.map(th => {
                                                                return (<th className={(th === "RRP (Inc GST)" || th === "Reseller Price (Ex GST)") ? "text-right" : ""}>{
                                                                    th === "Billing Term" ? <>{th}
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                        >
                                                                            <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                        </OverlayTrigger></> : th}</th>)
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            coreDowngradeDetailsStep2.map((td) => {
                                                                return (<tr>
                                                                    <td><input type='radio'
                                                                        id={td.offerSku}
                                                                        name="downgradeOptionsStep2"
                                                                        checked={defaultDowngradeStep2 === (td.offerSku)}
                                                                        onChange={() => {
                                                                            setDefaultDowngradeStep2(td.offerSku);
                                                                            setButtonCheckstep2(true);
                                                                            setAllSelectedDetailsStep2(td);
                                                                        }}
                                                                    />
                                                                    </td>
                                                                    <td>{td.planName}</td>
                                                                    <td>{td.billingCommitmentTerm}</td>
                                                                    <td className='text-right'>${td.rrpInclusiveTax}</td>
                                                                    <td className='text-right'>${td.price}</td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            }
                                            {coreDowngradeDetailsStep2?.length == 0 &&
                                                <div className={styles["no-data-cloud-invoice"]}>
                                                    <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                                    <h2 className="mt-4 mb-3">No Plans Found</h2>
                                                </div>
                                            }
                                        </div>
                                    </Typography>}
                                    {activeStep === 2 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        <div className={`table-responsive pt-3 ${styles['gws-downgrade-core-step3']}`}>
                                            {downgradeCoreTableStep3 &&
                                                <DataTable
                                                    columns={downgradeCoreColumnsStep3}
                                                    data={downgradeCoreTableStep3}
                                                    theme="solarized"
                                                    customStyles={customStylesDowngrade}
                                                    persistTableHead={false}
                                                    noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan Details . . .</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="31vh" progressPending={loading}
                                                    progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan Details . . .</p></div>}
                                                    fixedHeader
                                                />
                                            }
                                        </div>
                                    </Typography>}
                                    {/* buttons for downgrade */}
                                    {!serverError && !emptyDataError &&
                                        <div className='text-right pt-4'>
                                            {activeStep === 0 &&
                                                <React.Fragment>
                                                    {buttonCheckstep1 && <Button onClick={handleClearRows} className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light" sx={{ mr: 1 }}>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Next
                                                    </Button>}
                                                    {buttonCheckstep1 && <Button onClick={handleNextStep1}
                                                    >
                                                        Next
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 1 &&
                                                <React.Fragment>
                                                    <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mr: 1 }}
                                                    > Back  </Button>
                                                    {buttonCheckstep2 && <Button onClick={handleNextStep2}
                                                    > Next </Button>}
                                                    {!buttonCheckstep2 && <Button
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >Next </Button>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 2 &&
                                                <React.Fragment>
                                                    <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBackQuantity}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                    {<Button onClick={handleNextStep3}
                                                    >
                                                        Proceed
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                        </div>}
                                </React.Fragment>
                            </Box>
                        </Container>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default GWSDowngrade