import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './GWSChangeStatus.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { GOOGLE_WORKSPACE_CHANGE_STATUS_POST } from '../../../../../../../../Utils/GlobalConstants';
import Table from 'react-bootstrap/Table';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"


const GWSChangeStatus = ({ subscriptionTableAPIData, setSubscriptionTableAPIData, subscriptionId, changeStatusActionPopupModalOpen, setChangeStatusActionPopupModalOpen,
    Logout, setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable }) => {
    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup
    const [confirmSuspendFlag, setConfirmSuspendFlag] = useState(false);
    const [confirmChangeStatusLoadingFlag, setConfirmChangeStatusLoadingFlag] = useState(false);


    let ChangeStatusColumnNameSuspend = ["Plan", "Billing Term", "Reseller Price (Ex GST)","Renewal Price (Ex GST)*", "Quantity", "Refund"];
    let ChangeStatusColumnNameReActivate = ["Plan", "Billing Term", "Reseller Price (Ex GST)","Renewal Price (Ex GST)*", "Quantity"];
    //Stepper
    const steps = ['Select Plan', 'Select SKU', 'Add Quantity'];

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //post api for upgrade confirmation
    async function GwsChangeStatusPost(actionName) {
        setConfirmChangeStatusLoadingFlag(true);
        await api.post(GOOGLE_WORKSPACE_CHANGE_STATUS_POST + subscriptionId + actionName, null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmChangeStatusLoadingFlag(false);
                    setChangeStatusActionPopupModalOpen(false);
                    setSubscriptionTableAPIData(null);
                    let message
                    (actionName === "/suspend" ? message = "Subscription suspended successfully." : message = "Subscription is Reactivated Successfully.")
                    setToastMessage(message);
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                }
            })
            .catch(error => {
                setConfirmChangeStatusLoadingFlag(false);
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                let message;
                (actionName === "/suspend" ? message = "We encountered an issue while suspending the subscription. Please try again later." : message = "We encountered an issue while reactivating the subscription. Please try again later.")
                setToastMessage(message);
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);

            })
    };

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-md"
                open={confirmSuspendFlag}
                onClose={() => setConfirmSuspendFlag(false)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Change Status</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className="p-5">
                            <p className={`pt-0 px-3 pb-5 mb-0 ${styles['change-status-gws-confirmation-line']}`}>
                                {subscriptionTableAPIData && subscriptionTableAPIData.status === "ACTIVE" ? <strong>Are you sure you want to Suspend Subscription?</strong> : <strong>Are you sure you want to Re - Activate Subscription?</strong>}
                            </p>
                            <div className="actions">
                                <div className="d-flex justify-content-center pb-3 pt-2">
                                    <Button
                                        className="btn btn-lg mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                            close();
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        className="btn btn-md"
                                        onClick={() => {
                                            { subscriptionTableAPIData && subscriptionTableAPIData.status === "ACTIVE" ? GwsChangeStatusPost("/suspend") : GwsChangeStatusPost("/activate") };
                                            close();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            </Popup>
            <Popup
                className="custom-modal custom-modal-xl"
                open={changeStatusActionPopupModalOpen}
                onClose={() => {setChangeStatusActionPopupModalOpen(false);setSubscriptionTableAPIData(null);}}
                modal
                closeOnDocumentClick={!confirmChangeStatusLoadingFlag}
                nested
            >
                {(close) => (
                    <div className="modal">
                        {!subscriptionDetailIsLoadingFlag &&
                            <>
                                <div className="header">
                                    <h2>Change Status</h2>
                                    <XLg
                                        size={18}
                                        className="cpointer text-strong"
                                        onClick={() => { if (!confirmChangeStatusLoadingFlag) close() }}
                                        color="#6A6A6A"
                                    />
                                </div>
                                <Container  className={` action-modal-height pt-0 pb-3 ${styles['modal-width']}`}>
                                    <Box sx={{ width: '100%' }}>
                                        <React.Fragment>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                {subscriptionTableAPIData && (subscriptionTableAPIData.status === "ACTIVE" ? <p className='text-strong'>Suspend Subscription</p> : <p className='text-strong'>Re - Activate Subscription</p>)}
                                                <div className={`table-responsive ${styles['gws-downgrade-core']}`}>
                                                    {subscriptionTableAPIData &&
                                                        <Table className={`${styles['gws-change-status-core-table']}`} responsive="md">
                                                            <thead className="sticky-top">
                                                                <tr>
                                                                    {subscriptionTableAPIData.status === "ACTIVE" ? ChangeStatusColumnNameSuspend.map(th => {
                                                                        return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity" || th==="Renewal Price (Ex GST)*") ? "text-right" : ""}>{
                                                                            th === "Billing Term" ? <>{th}
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                                >
                                                                                    <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                                </OverlayTrigger></> : th}</th>)
                                                                    }) :
                                                                        ChangeStatusColumnNameReActivate.map(th => {
                                                                            return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity" || th==="Renewal Price (Ex GST)*") ? "text-right" : ""}>{
                                                                                th === "Billing Term" ? <>{th}
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}

                                                                                    >
                                                                                        <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                                                    </OverlayTrigger></> : th}</th>)
                                                                        })
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    <tr>
                                                                        <td>{subscriptionTableAPIData.planName}</td>
                                                                        <td>{subscriptionTableAPIData.billingTerm}</td>
                                                                        <td className={`text-right ${styles['reseller-price']}`}>${subscriptionTableAPIData.price}</td>
                                                                        <td className={`text-right ${styles['reseller-price']}`}>${subscriptionTableAPIData.rrpExclusiveTax}</td>
                                                                        <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                                                                        {subscriptionTableAPIData.status === "ACTIVE" && <td>{subscriptionTableAPIData.refund}</td>}
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    }
               
                                                    {!subscriptionTableAPIData &&
                                                        <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading . . .</p></div>
                                                    }
                                                </div>
                                            </Typography>
                                            {/* buttons for downgrade */}
                                            <div className='text-right pt-4 pb-4'>
                                                <React.Fragment>
                                                    <Row>
                                                        <Col md={9} className='mt-2'>
                                                        {subscriptionTableAPIData &&
                                                        <p className={`pt-1 pb-3 ${styles['note-p']}`}><span className='text-strong'>Note* :</span> Renewal price is based on current pricing. This will change if new prices are published before renewal by google.</p>
                                                        }
                                                        </Col>
                                                        <Col>
                                                        {!confirmChangeStatusLoadingFlag && subscriptionTableAPIData && <Button
                                                        onClick={() => setConfirmSuspendFlag(true)}
                                                    >
                                                        {subscriptionTableAPIData.status === "ACTIVE" ? "Suspend Subscription" : "Re-Activate Subscription"}
                                                    </Button>}
                                                    {confirmChangeStatusLoadingFlag && subscriptionTableAPIData && <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>}
                                                        </Col>
                                                    </Row>
                                                    
                                                </React.Fragment>
                                            </div>
                                        </React.Fragment>

                                    </Box>
                                </Container>
                            </>
                        }
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default GWSChangeStatus
